import React from "react";
import { Container, Modal } from "react-bootstrap";
import moment from "moment";

const AccountExpiryNotifyModal = ({ message={}, show, closeModal }) => {
  const formatDate = (date) =>{
   const formattedDate = date?.format("DD/MM/yyyy");
   return formattedDate;
  };

  const getWeeks = (days, withMore = false) => {
    if(days < 7)
        return withMore ? `${days} more day(s)` : days;
    const week = Math.floor(Math.abs(days / 7) || 1);
    return week === 1 ? `one ${withMore ? "more" : '' } week` : `two ${withMore ? "more" : ''} weeks`;
  };

  const getMessage = ({
    expiryDate,
    trialDate,
    supportNo,
    expiryDiff,
    trialDiff,
  } = {}) => {
    if (expiryDiff < 0 && trialDiff < 0) {
      return (
        <>
          Your Locator account expired {formatDate(expiryDate) !== "31/01/1970" ? `on ${formatDate(expiryDate)}` : ""},{" "}
          and your trial access ended
          on {formatDate(trialDate)}.
          <br />
          To restore access to the application, Please reach out to our team at{" "}
          <a
            href="mailto:info@locator.ae"
            style={{ color: "blue", textDecoration: "none" }}
          >
            info@locator.ae
          </a>{" "}
          or via WhatsApp at{" "}
          <a
            href={`https://wa.me/${supportNo}`}
            style={{ color: "blue", textDecoration: "none" }}
          >
            {supportNo}
          </a>
          .<br />
          We are here to assist you.
        </>
      );
    } else if (expiryDiff < 0 && trialDiff >= 0) {
      return (
        <>
          Your LOCATOR account expired {expiryDate !== "31/01/1970" ? `on ${formatDate(moment(expiryDate))}` : ""}.
          <br />
          However, your trial access remains active for {getWeeks(
            trialDiff, true
          )}{" "} ({formatDate(moment(trialDate))}). {" "}
          To ensure uninterrupted access to the application, please contact our team at{" "}
          <a
            href="mailto:info@locator.ae"
            style={{ color: "blue", textDecoration: "none" }}
          >
            info@locator.ae
          </a>{" "}
          or via WhatsApp at{" "}
          <a
            href={`https://wa.me/${supportNo}`}
            style={{ color: "blue", textDecoration: "none" }}
          >
            {supportNo}
          </a>
          .<br />
          We are here to assist you.
        </>
      );
    } else if (expiryDiff >= 0 && expiryDiff <=14) {
      return (
        <>
          Your LOCATOR account is scheduled to expire in {getWeeks(expiryDiff, false)}{" "}
          ({formatDate(moment(trialDate))}).
          <br />
          To ensure uninterrupted access to the application, please contact our
          team at {" "}
          <a
            href="mailto:info@locator.ae"
            style={{ color: "blue", textDecoration: "none" }}
          >
            info@locator.ae
          </a>{" "}
          or via WhatsApp at{" "}
          <a
            href={`https://wa.me/${supportNo}`}
            style={{ color: "blue", textDecoration: "none" }}
          >
            {supportNo}
          </a>
          .<br />
          We are here to assist you.
        </>
      );
    } else
      return (
        <>
          An unknown error occurred. Please contact support at (info@locator.ae) or whatsapp at {supportNo}.
        </>
      );
  };

  return (
    <Modal
      show={show}
      dialogClassName="modal-40w"
      onHide={closeModal}
      aria-labelledby="accountexpirynotifymodal"
    >
      <Modal.Header closeButton>
        <span className="text-left" style={{ width: "80%" }}>
          Alert :
        </span>
      </Modal.Header>

      <Modal.Body className="show-grid">
        <Container>
          <span style={styles.messageStyle}>{getMessage(message)}</span>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <button
          type="button"
          style={{
            fontSize: "12px",
            backgroundColor: "#007bff",
            color: "#ffffff",
          }}
          className="btn btn-small"
          onClick={closeModal}
        >
          OK
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const styles = {
  messageStyle: {
    fontSize: "13px",
    fontWeight: "medium",
    color: "#444",
  },
};

export default AccountExpiryNotifyModal;
