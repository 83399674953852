import {
  GET_REPORT_GROUP_NAMES,
  GET_SUBUSERS,
  SET_GROUPS,
  TOGGLE_VEHICLE_CHECKED,
  TOGGLE_VEHICLE_GROUP_CHECKED,
  GET_REPORT_NAMES,
  TOGGLE_SELECT_REPORT,
  GET_SUBUSER,
  GET_SHARE_LOCATION_SETTINGS,
  GET_SINGLE_OBJ_GROUP,
  GET_REPORT_SCHEDULERS,
  GET_REPORT_SCHEDULE,
  GET_SINGLE_REPORT_SCHEDULER,
  GET_NOTIFICATIONS,
  GET_GEOZONES,
  ON_SELECT_GEOZONE,
  ON_SELECT_ALL_GEOZONE,
  GET_SINGLE_NOTIFICATION,
  GET_DASHBOARD_SETTINGS,
  GET_TASK_CHECK_SETTINGS,
  GET_TASK_MAP_SETTINGS,
  GET_TASK_REVIEW_SETTINGS,
  GET_TASK_ATTRIBUTES,
  GET_REPORT_CONFORMATION_DETAILS,
  GET_TASK_SETTINGS,
  GET_SYSTEM_SETTINGS,
  GET_TASK_CUSTOMER_GROUPS,
  GET_TASK_DRIVER_GROUPS,
  GET_SINGLE_TASK_DRIVER_GROUPS,
  GET_TASK_SUBUSER,
  GET_TASK_DRIVERS,
  GET_TASK_DRIVERS_OF_SUBUSER,
  GET_ORGANIZATION_SETTING,
  GET_ORGANIZATION_VEHICLES,
  GET_ORGANIZATION_VEHICLES_BASED_ON_KEY,
  GET_GRAPHICAL_REPORTS,
  GET_ORGANIZATION_STORAGE,
  SWITCH_LANGUAGE,
  UPDATE_INSPECTION_SETTINGS,
  GET_INSPECTION_SETTINGS,
  GET_COMMUNITY_SETTINGS,
} from "../actions/setting.action";

const initialState = {
  vehicles: [],
  vehiclesObject: {},
  groups: [],
  graphicalReports: [],
  selectedVehicles: [],
  reportGroupNames: [],
  reportNames: [],
  selectedReports: [],
  subusers: [],
  ActiveSubuserToEdit: null,
  // live setting
  shareLocationSettings: false,
  singleObjectGroup: false,
  // report scheduler
  reportSchedulers: [],
  reportSchedule: [],
  activeReportScheduler: false,
  selectedObjects: [],
  // Notifications
  notifications: [],
  geozones: false,
  selectedGeozones: [],
  activeNotification: false,
  // Dashboard Setting
  dashboardSettings: [],
  // Task manager settings
  taskCheckSettings: false,
  taskMapSettings: false,
  taskReviewSettings: false,
  taskAttributes: [],
  reportConformationDetails: false,
  taskSettings: false,
  systemSettings: false,
  taskCusomreGroups: [],
  activeLocation: false,
  taskDriverGroups: [],
  activeTaskDriverGroup: false,
  taskSubusers: [],
  taskDrivers: [],
  selectedDrivers: [],
  organizationStorage: false,
  organizationSettings: {
    fuelRates: {
      Petrol: 10,
      Diesel: 7,
      Gas: 3,
      Other: 5,
    },
  },
  language : "en", // or "ar"
  // Inspection Settings
  inspectionSettings: {
    inspectionFormSections: {},
    inspectionTypeSettings: {},
    mandatoryFields: [],
    optionalFields: [],
  },

  // community settings
  communitySettings: {},
};

const SettingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GROUPS:
      return { ...state, groups: action.value, checkedVehicles: [] };

    case GET_ORGANIZATION_VEHICLES:
      return { ...state, vehicles: action.value };

    case GET_ORGANIZATION_VEHICLES_BASED_ON_KEY:
      return { ...state, vehiclesObject: action.value };

    case GET_ORGANIZATION_STORAGE:
      return { ...state, organizationStorage: action.value };

    case TOGGLE_VEHICLE_CHECKED:
      const cloneData = [...state.groups];
      var v = [];

      for (let index = 0; index < cloneData.length; index++) {
        const group = cloneData[index];
        group.vehicles.map((vehicle) => {
          if (vehicle.uniqueId == action.value.id) {
            vehicle.checked = !vehicle.checked;
          }
        });
      }

      for (let index = 0; index < cloneData.length; index++) {
        const group = cloneData[index];
        group.vehicles.forEach((vehicle) => {
          if (vehicle.checked == true) v.push(vehicle.uniqueId);
        });
      }

      return {
        ...state,
        groups: cloneData,
        selectedVehicles: v,
      };

    case TOGGLE_VEHICLE_GROUP_CHECKED:
      const cloneForVehicleGroup = [...state.groups];
      var v = [];

      var i = cloneForVehicleGroup.findIndex((g) => g.id == action.value.id);

      if (i !== -1) {
        cloneForVehicleGroup[i].vehicles.forEach((vehicle) => {
          vehicle.checked = action.value.checked;
        });
      }

      for (let index = 0; index < cloneForVehicleGroup.length; index++) {
        const group = cloneForVehicleGroup[index];
        group.vehicles.forEach((vehicle) => {
          if (vehicle.checked) v.push(vehicle.uniqueId);
        });
      }

      return {
        ...state,
        groups: cloneForVehicleGroup,
        selectedVehicles: v,
      };

    case GET_SUBUSERS:
      return { ...state, subusers: action.value };

    case GET_SUBUSER:
      const reports = action.value.reports;

      return {
        ...state,
        ActiveSubuserToEdit: action.value,
      };

    case GET_REPORT_GROUP_NAMES:
      return { ...state, reportGroupNames: action.value, selectedReports: [] };

    case GET_REPORT_NAMES:
      return { ...state, reportNames: action.value, selectedReports: [] };

    case TOGGLE_SELECT_REPORT:
      var cloneReportNames = [...state.reportNames];
      var cloneSelectedReports = [...state.selectedReports];

      var i = cloneReportNames.findIndex((r) => r.report_id == action.value.id);
      if (i !== -1) {
        cloneReportNames[i].checked = !cloneReportNames[i].checked;
      }

      if (action.value.checked == true) {
        cloneSelectedReports.push(action.value.id);
      } else {
        cloneSelectedReports = cloneSelectedReports.filter(
          (cloneID) => cloneID !== action.value.id
        );
      }

      return {
        ...state,
        selectedReports: cloneSelectedReports,
        reportNames: cloneReportNames,
      };

    // Settings

    case GET_SHARE_LOCATION_SETTINGS:
      return { ...state, shareLocationSettings: action.value };

    case GET_SINGLE_OBJ_GROUP:
      return { ...state, singleObjectGroup: action.value };

    // Report schedular

    case GET_REPORT_SCHEDULERS:
      return { ...state, reportSchedulers: action.value };

    case GET_REPORT_SCHEDULE:
      return { ...state, reportSchedule: action.value };

    case GET_SINGLE_REPORT_SCHEDULER:
      return { ...state, activeReportScheduler: action.value };

    // Notifications

    case GET_NOTIFICATIONS:
      return { ...state, notifications: action.value };

    case GET_GEOZONES:
      return { ...state, geozones: action.value, selectedGeozones: [] };

    case GET_SINGLE_NOTIFICATION:
      return { ...state, activeNotification: action.value };

    case ON_SELECT_GEOZONE:
      var cloneSelectedGeozones = [...state.selectedGeozones];
      var exist = cloneSelectedGeozones.findIndex(
        (gz) => gz == action.value.id
      );

      if (exist !== -1) {
        if (!action.value.checked) cloneSelectedGeozones.splice(exist, 1);
      } else {
        if (action.value.checked) cloneSelectedGeozones.push(action.value.id);
      }

      return { ...state, selectedGeozones: cloneSelectedGeozones };

    case ON_SELECT_ALL_GEOZONE:
      var cloneSelectedGeozones = [...state.selectedGeozones];

      action.value.ids.map((id) => {
        var exist = cloneSelectedGeozones.findIndex((gz) => gz == id);

        if (exist !== -1) {
          if (!action.value.checked) cloneSelectedGeozones.splice(exist, 1);
        } else {
          if (action.value.checked) cloneSelectedGeozones.push(id);
        }
      });

      return { ...state, selectedGeozones: cloneSelectedGeozones };

    // Dashboard Settings

    case GET_DASHBOARD_SETTINGS:
      return { ...state, dashboardSettings: action.value };

    // Task Manager Settings

    case GET_TASK_CHECK_SETTINGS:
      return { ...state, taskCheckSettings: action.value };

    case GET_TASK_MAP_SETTINGS:
      return { ...state, taskMapSettings: action.value };

    case GET_TASK_REVIEW_SETTINGS:
      return { ...state, taskReviewSettings: action.value };

    case GET_TASK_ATTRIBUTES:
      return { ...state, taskAttributes: action.value };

    case GET_REPORT_CONFORMATION_DETAILS:
      return { ...state, reportConformationDetails: action.value };

    case GET_TASK_SETTINGS:
      return { ...state, taskSettings: action.value };

    case GET_SYSTEM_SETTINGS:
      return { ...state, systemSettings: action.value };

    case GET_TASK_CUSTOMER_GROUPS:
      return { ...state, taskCusomreGroups: action.value };

    case GET_TASK_DRIVER_GROUPS:
      return { ...state, taskDriverGroups: action.value };

    case GET_SINGLE_TASK_DRIVER_GROUPS:
      return { ...state, activeTaskDriverGroup: action.value };

    case GET_TASK_SUBUSER:
      return { ...state, taskSubusers: action.value };

    case GET_TASK_DRIVERS:
      return { ...state, taskDrivers: action.value };

    case GET_TASK_DRIVERS_OF_SUBUSER:
      return { ...state, selectedDrivers: action.value };

    case GET_ORGANIZATION_SETTING:
      return { ...state, organizationSettings: action.value };

    case GET_GRAPHICAL_REPORTS:
      return { ...state, graphicalReports: action.value };

    case SWITCH_LANGUAGE : 
      return {...state, language : action.value};

    case GET_INSPECTION_SETTINGS:
      return { ...state, inspectionSettings: action.value };

    case UPDATE_INSPECTION_SETTINGS:
      return {
        ...state,
        inspectionSettings: {
          ...state.inspectionSettings,
          [action.key]: action.value,
        },
      };

    case GET_COMMUNITY_SETTINGS:
      if (action.key)
        return {
          ...state,
          communitySettings: {
            ...state.communitySettings,
            [action.key]: action.value,
          },
        };
      return { ...state, communitySettings: action.value };

    default:
      return state;
  }
};

export default SettingReducer;
