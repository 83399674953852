import moment from "moment";
import GetRequest from "../../services/GetRequest";
import LaravelReportServerRequest from "../../services/LaravelReporServerReqeust";
import { getStorage } from "../../services/localStorageService";
import NodeReportsRequest from "../../services/NodeReportsRequest";
import ReportRequest from "../../services/ReportRequest";
import TaskManagerRequest from "../../services/TaskManagerRequest";
import { ShowNotification } from "../../services/Notifications";

export const SET_GROUPS = "SET_GROUPS";
export const REPORT_TOGGLE_SELECTED_VEHICLE = "REPORT_TOGGLE_SELECTED_VEHICLE";
export const REPORT_REMOVE_SELECTED_VEHICLE = "REPORT_REMOVE_SELECTED_VEHICLE";
export const REPORT_TOGGLE_SELECTED_VEHICLE_GROUP =
  "REPORT_TOGGLE_SELECTED_VEHICLE_GROUP";
export const GET_REPORT_GROUP_NAMES = "GET_REPORT_GROUP_NAMES";
export const GET_REPORT_NAMES = "GET_REPORT_NAMES";

export const TOGGLE_REPORT_LODER = "TOGGLE_REPORT_LODER";
export const TOTAL_FOUND_REPORTS = "TOTAL_FOUND_REPORTS";

export const SET_SHOW_MAP = "SET_SHOW_MAP";
export const SET_REPORT_DATA = "SET_REPORT_DATA";

export const SET_DISTANCE_REPORT = "SET_DISTANCE_REPORT";
export const SET_DETAILED_REPORT = "SET_DETAILED_REPORT";
export const SET_DRIVER_BASED_DETAILED_REPORT =
  "SET_DRIVER_BASED_DETAILED_REPORT";
export const SET_DOOR_STATUS_REPORT = "SET_DOOR_STATUS_REPORT";
export const SET_IDLING_REPORT = "SET_IDLING_REPORT";
export const SET_GEOZONE_REPORT = "SET_GEOZONE_REPORT";
export const SET_POI_REPORT = "SET_POI_REPORT";
export const SET_TRIP_AND_PARKING_REPORT = "SET_TRIP_AND_PARKING_REPORT";
export const SET_DAILY_ATTANDANCE_REPORT = "SET_DAILY_ATTANDANCE_REPORT";
export const SET_FULE_COST_REPORT = "SET_FULE_COST_REPORT";
export const SET_EXCESSIVE_IDLING_REPORT = "SET_EXCESSIVE_IDLING_REPORT";
export const SET_OFFICE_REPORT = "SET_OFFICE_REPORT";
export const SET_SERVICE_ENTRY_REPORT = "SET_SERVICE_ENTRY_REPORT";
export const SET_FULE_ENTRY_REPORT = "SET_FULE_ENTRY_REPORT";
export const SET_SPEED_REPORT = "SET_SPEED_REPORT";
export const SET_DOCUMENT_REMINDER_REPORT = "SET_DOCUMENT_REMINDER_REPORT";
export const SET_SERVICE_REMINDER_REPORT = "SET_SERVICE_REMINDER_REPORT";
export const SET_DRIVER_BASED_REPORT = "SET_DRIVER_BASED_REPORT";
export const SET_NEW_SALIK_REPORT = "SET_NEW_SALIK_REPORT";
export const SET_SEAT_VIOLATION_REPORT = "SET_SEAT_VIOLATION_REPORT";
export const SET_DUTY_TIME_REPORT = "SET_DUTY_TIME_REPORT";
export const SET_SUMMARY_IDLING_REPORT = "SET_SUMMARY_IDLING_REPORT";
export const SET_CHECK_IN_REPORT = "SET_CHECK_IN_REPORT";
export const SET_ROUTE_REPORT = "SET_ROUTE_REPORT";
export const SET_DRIVER_REPORT = "SET_DRIVER_REPORT";
export const SET_TRIP_REPORT = "SET_TRIP_REPORT";
export const SET_SUMMARY_DISTANCE_REPORT = "SET_SUMMARY_DISTANCE_REPORT";
export const SET_PARKING_REPORT = "SET_PARKING_REPORT";
export const SET_ZONE_CALLS_REPORT = "SET_ZONE_CALLS_REPORT";
export const SET_DRIVER_BEHAVIOUR_REPORT = "SET_DRIVER_BEHAVIOUR_REPORT";
export const SET_AC_WORKING_REPORT = "SET_AC_WORKING_REPORT";
export const SET_SUMMARY_FUEL_COST_REPORT = "SET_SUMMARY_FUEL_COST_REPORT";
export const SET_SUMMARY_DRIVER_IDLING_REPORT =
  "SET_SUMMARY_DRIVER_IDLING_REPORT";
export const SET_SUMMARY_DRIVER_REPORT = "SET_SUMMARY_DRIVER_REPORT";
export const SET_DRIVER_ATTENDANCE_REPORT = "SET_DRIVER_ATTENDANCE_REPORT";
export const SET_WEEKLY_COMPARISION_REPORT = "SET_WEEKLY_COMPARISION_REPORT";
export const SET_SUMMARY_GRAPHICAL_REPORT = "SET_SUMMARY_GRAPHICAL_REPORT";
export const SET_IDLING_WITH_DRIVER_REPORT = "SET_IDLING_WITH_DRIVER_REPORT";
export const SET_TASK_MANAGER_REPORT = "SET_TASK_MANAGER_REPORT";
export const SET_DAILY_TRIP_SUMMARY_BETA_REPORT =
  "SET_DAILY_TRIP_SUMMARY_BETA_REPORT";
export const SET_ACTIVE_IDLING = "SET_ACTIVE_IDLING";
export const SET_ACTIVE_PARKING = "SET_ACTIVE_PARKING";
export const SET_FUEL_GRAPH_CAN_REPORT = "SET_FUEL_GRAPH_CAN_REPORT";
export const SET_FUEL_GRAPH_CAN_IN_GALLON_REPORT =
  "SET_FUEL_GRAPH_CAN_IN_GALLON_REPORT";
export const SET_ASSETS_REPORT = "SET_ASSETS_REPORT";
export const SET_REPORT_DATA_FOR_PDF = "SET_REPORT_DATA_FOR_PDF";
export const SET_ATTENDANCE_REPORT_MVL = "SET_ATTENDANCE_REPORT_MVL";
export const SET_DISTANCE_REPORT_POI = "SET_DISTANCE_REPORT_POI";
export const SET_ASPEN_REPORT = "SET_ASPEN_REPORT";
export const SET_ASSETS_REPORT_EXCLUSIVE_SUNDAY =
  "SET_ASSETS_REPORT_EXCLUSIVE_SUNDAY";
export const SET_DRIVER_ATTENDANCE_OVERTIME_REPORT =
  "SET_DRIVER_ATTENDANCE_OVERTIME_REPORT";
export const SET_DRIVER_OVERTIME_REPORT = "SET_DRIVER_OVERTIME_REPORT";
export const SET_HOLIDAY_TRIP_REPORT = "SET_HOLIDAY_TRIP_REPORT";
export const SET_HOLIDAY_REPORT_WITH_FUEL_COST =
  "SET_HOLIDAY_REPORT_WITH_FUEL_COST";
export const SET_ODOMETER_REPORT = "SET_ODOMETER_REPORT";
export const SET_NEW_ODOMETER_REPORT = "SET_NEW_ODOMETER_REPORT";
export const SET_WEIGHT_REPORT = "SET_WEIGHT_REPORT";
export const SET_WEIGHT_REPORT_THORE = "SET_WEIGHT_REPORT_THORE";
export const SET_DAILY_ASSET_REPORT = "SET_DAILY_ASSET_REPORT";
export const SET_MILEAGE_REPORT_CAN = "SET_MILEAGE_REPORT_CAN";
export const SET_FULE_CUNSUMPTION_REPORT = "SET_FULE_CUNSUMPTION_REPORT";
export const SET_SUMMARY_GRAPHICAL_REPORT_WITH_WEIGHT =
  "SET_SUMMARY_GRAPHICAL_REPORT_WITH_WEIGHT";
export const SET_SAMSUNG_TASK_MANAGER_REPORT =
  "SET_SAMSUNG_TASK_MANAGER_REPORT";
export const SET_TEMPRATURE_REPORT = "SET_TEMPRATURE_REPORT";
export const SET_GRAPHICAL_ATTENDANCE = "SET_GRAPHICAL_ATTENDANCE";
export const SET_GRAPHICAL_SALIK_DARB = "SET_GRAPHICAL_SALIK_DARB";
export const SET_TRIP_BASED_FUEL_CONSUMPTION_REPORT =
  "SET_TRIP_BASED_FUEL_CONSUMPTION_REPORT";
export const SET_DRIVER_RAG_REPORT = "SET_DRIVER_RAG_REPORT";
export const SET_DRIVER_BASED_IDLING_REPORT = "SET_DRIVER_BASED_IDLING_REPORT";
export const SET_DRIVER_BASED_AFTER_OFFICE_HOURS_REPORT =
  "SET_DRIVER_BASED_AFTER_OFFICE_HOURS_REPORT";
export const SET_VEHICLE_INVENTORY_REPORT = "SET_VEHICLE_INVENTORY_REPORT";
export const SET_UNUTILIZED_VEHICLES_REPORT = "SET_UNUTILIZED_VEHICLES_REPORT";

const REPORTS_LIST_KEYS = {
  1: SET_DISTANCE_REPORT,
  2: SET_TRIP_AND_PARKING_REPORT,
  4: SET_SUMMARY_DISTANCE_REPORT,
  5: SET_TRIP_REPORT,
  6: SET_PARKING_REPORT,
  7: SET_SPEED_REPORT,
  8: SET_DOCUMENT_REMINDER_REPORT,
  9: SET_SERVICE_REMINDER_REPORT,
  10: SET_SERVICE_ENTRY_REPORT,
  11: SET_FULE_ENTRY_REPORT,
  13: SET_DETAILED_REPORT,
  15: SET_IDLING_REPORT,
  17: SET_GEOZONE_REPORT,
  24: SET_POI_REPORT,
  19: SET_DRIVER_REPORT,
  20: SET_DRIVER_BEHAVIOUR_REPORT,
  29: SET_TEMPRATURE_REPORT,
  30: SET_DAILY_ATTANDANCE_REPORT,
  37: SET_ZONE_CALLS_REPORT,
  38: SET_FULE_COST_REPORT,
  40: SET_OFFICE_REPORT,
  41: SET_AC_WORKING_REPORT,
  42: SET_DETAILED_REPORT,
  44: SET_DOOR_STATUS_REPORT,
  48: SET_DRIVER_BASED_REPORT,
  49: SET_SUMMARY_GRAPHICAL_REPORT,
  50: SET_TASK_MANAGER_REPORT,
  51: SET_DAILY_TRIP_SUMMARY_BETA_REPORT,
  52: SET_HOLIDAY_TRIP_REPORT,
  54: SET_WEEKLY_COMPARISION_REPORT,
  56: SET_IDLING_WITH_DRIVER_REPORT,
  57: SET_NEW_SALIK_REPORT,
  58: SET_SEAT_VIOLATION_REPORT,
  59: SET_DUTY_TIME_REPORT,
  60: SET_SUMMARY_IDLING_REPORT,
  62: SET_EXCESSIVE_IDLING_REPORT,
  63: SET_CHECK_IN_REPORT,
  64: SET_ROUTE_REPORT,
  66: SET_ASSETS_REPORT,
  67: SET_SUMMARY_FUEL_COST_REPORT,
  68: SET_SUMMARY_DRIVER_IDLING_REPORT,
  69: SET_SUMMARY_DRIVER_REPORT,
  70: SET_ASPEN_REPORT,
  72: SET_ASSETS_REPORT_EXCLUSIVE_SUNDAY,
  73: SET_DRIVER_ATTENDANCE_REPORT,
  74: SET_SAMSUNG_TASK_MANAGER_REPORT,
  75: SET_DRIVER_OVERTIME_REPORT,
  76: SET_NEW_ODOMETER_REPORT,
  77: SET_WEIGHT_REPORT,
  78: SET_DRIVER_ATTENDANCE_OVERTIME_REPORT,
  79: SET_SUMMARY_GRAPHICAL_REPORT_WITH_WEIGHT,
  80: SET_FUEL_GRAPH_CAN_REPORT,
  81: SET_DISTANCE_REPORT_POI,
  82: SET_ODOMETER_REPORT,
  83: SET_WEIGHT_REPORT_THORE,
  85: SET_ATTENDANCE_REPORT_MVL,
  87: SET_DAILY_ASSET_REPORT,
  89: SET_MILEAGE_REPORT_CAN,
  90: SET_FULE_CUNSUMPTION_REPORT,
  92: SET_DRIVER_BASED_DETAILED_REPORT,
  93: SET_FUEL_GRAPH_CAN_IN_GALLON_REPORT,
  119: SET_HOLIDAY_REPORT_WITH_FUEL_COST,
  123: SET_TRIP_BASED_FUEL_CONSUMPTION_REPORT,
  128: SET_DRIVER_RAG_REPORT,
  132: SET_DRIVER_BASED_IDLING_REPORT,
  133: SET_DRIVER_BASED_AFTER_OFFICE_HOURS_REPORT,
  140: SET_VEHICLE_INVENTORY_REPORT,
  141: SET_UNUTILIZED_VEHICLES_REPORT,
};

export const nodeReportIds = [38, 57, 133, 140, 141];
export const noVehicleRequiredReports = [140, 141];

export const setGroups = () => {
  const groups = getStorage("__groups__", "object");

  return (dispatch) => {
    dispatch({
      type: SET_GROUPS,
      value: groups,
    });
  };
};

export const toggleSelectedVehicleGroup = (data) => {
  return (dispatch) => {
    dispatch({
      type: REPORT_TOGGLE_SELECTED_VEHICLE_GROUP,
      value: data,
    });
  };
};

export const toggleSelectedVehicle = (vehicle) => {
  return (dispatch) => {
    dispatch({
      type: REPORT_TOGGLE_SELECTED_VEHICLE,
      value: vehicle,
    });
  };
};

export const removeSelectedVehicle = () => {
  return (dispatch) => {
    dispatch({
      type: REPORT_REMOVE_SELECTED_VEHICLE,
      value: [],
    });
  };
};

export const removeDriverBehaviorReportData = () => {
  return (dispatch) => {
    dispatch({
      type: SET_DRIVER_BEHAVIOUR_REPORT,
      value: [],
    });
  };
};

export const getReportGroupNames = (type = "") => {
  return async (dispatch) => {
    var response = await GetRequest(
      "/reports/getReportGroupNames?type=" + type,
      {}
    );
    if (type == "g")
      response = [
        { report_group_id: -1, report_group_name: "New Graphical Reports" },
        ...response,
      ];

    dispatch({
      type: GET_REPORT_GROUP_NAMES,
      value: response,
    });
  };
};

export const getReportNames = (type = "") => {
  return async (dispatch) => {
    var response = await GetRequest("/reports/getReportNames?type=" + type, {});

    if (type == "g") {
      var userId = null;

      try {
        const user = JSON.parse(localStorage.getItem("__user__"));
        userId = user.id;
      } catch (error) {}

      var permissions = await TaskManagerRequest("/get/modules", {
        userId: userId,
      });
      var data = [];

      if (
        permissions &&
        permissions.reports &&
        permissions.reports.graphicalReports
      ) {
        permissions.reports.graphicalReports.map((r) => {
          if (r.report_status == "active") data.push(r);
        });
      }

      // var data = [
      //     { report_group_id: -1, report_id: 49, report_name: "Summary Graphical Report", report_status: "active" },
      //     { report_group_id: -1, report_id: 1001, report_name: "Attendance Report", report_status: "active" },
      //     { report_group_id: -1, report_id: 1002, report_name: "Excessive Idling", report_status: "active" },
      //     { report_group_id: -1, report_id: 1003, report_name: "After Office Hours Usage", report_status: "active" },
      //     { report_group_id: -1, report_id: 1004, report_name: "Vehicle Cost Report", report_status: "active" },
      //     { report_group_id: -1, report_id: 1005, report_name: "Salik & DARB Report", report_status: "active" },
      //     { report_group_id: -1, report_id: 1006, report_name: "Driver Behavior Report", report_status: "active" },
      //     // { report_group_id: -1, report_id: 1007, report_name: "Excess Kilometer Usage Report", report_status: "active" },
      //     { report_group_id: -1, report_id: 1008, report_name: "Weekly Comparison Report", report_status: "active" },
      //     { report_group_id: -1, report_id: 29, report_name: "Tempurature Report", report_status: "active" },
      //     { report_group_id: -1, report_id: 10010, report_name: "Out of Zone usage", report_status: "active" },
      //     { report_group_id: -1, report_id: 10011, report_name: "Fuel Report [CAN]", report_status: "active" },
      //     // { report_group_id: -1, report_id: 10012, report_name: "Weekend Usage Report", report_status: "active" },
      //     // { report_group_id: -1, report_id: 10013, report_name: "Analog Fuel Level Sensor Report", report_status: "active" },
      //     { report_group_id: -1, report_id: 10014, report_name: "Fuel Cost Report", report_status: "active" },
      // ]

      response = [...response, ...data];
    }

    dispatch({
      type: GET_REPORT_NAMES,
      value: response,
    });
  };
};

export const loadSalikDarbReports = (data) => {
  return async (dispatch) => {
    dispatch({
      type: TOGGLE_REPORT_LODER,
      value: true,
    });

    const response = await TaskManagerRequest("/salik-darb/report", data);

    dispatch({
      type: SET_GRAPHICAL_SALIK_DARB,
      value: response,
    });

    dispatch({
      type: TOGGLE_REPORT_LODER,
      value: false,
    });
  };
};

export const loadAttendanceReport = (data) => {
  return async (dispatch) => {
    dispatch({
      type: TOGGLE_REPORT_LODER,
      value: true,
    });

    const response = await TaskManagerRequest("/attendance/report", data);

    dispatch({
      type: SET_GRAPHICAL_ATTENDANCE,
      value: response,
    });

    dispatch({
      type: TOGGLE_REPORT_LODER,
      value: false,
    });
  };
};

export const reportCreator = (data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: TOGGLE_REPORT_LODER,
        value: true,
      });

      // ConvertGraphicalReportId to ActualData
      var graphIDConvertor = {
        1001: 30,
        1002: 62,
        1006: 20,
        1007: 5,
        1008: 54,
        // 1003: 13,
        1003: 40,
        10015: 40,
        10010: 17,
        10011: 80,
        10014: 38,
        10016: 7,
      };

      if (graphIDConvertor[data.reportID]) {
        data.reportID = graphIDConvertor[data.reportID];
      }

      let response;
      const today = moment().format("DD-MM-yyyy");

      if (data.reportID == 17 && data.fromDate.startsWith(today)) {
        const selectedGzones = Object.keys(data.rptGzIDs)?.length || 0;
        const selectedDevices = data?.vehIDs?.length || 0;

        if (selectedGzones > 10 || selectedDevices > 10) {
          dispatch({
            type: TOGGLE_REPORT_LODER,
            value: false,
          });
         ShowNotification(
            "warning",
            "While generating geozone report for today's date, you can select either up to 10 vehicles or up to 10 geozones at a time. "
          );
          return;
        }
        response = await ReportRequest("/ReportCreator", JSON.stringify(data));
      } else if (data.reportID == 17) {
        response = await LaravelReportServerRequest(
          "/ReportCreator",
          JSON.stringify(data)
        );
      } else {
        response = await ReportRequest("/ReportCreator", JSON.stringify(data));
      }

      if (response && response[0] && response[0].csrf) {
        window.localStorage.setItem("_token", response[0].csrf);
      }

      const KEY = REPORTS_LIST_KEYS[data.reportID.toString()];

      dispatch({
        type: KEY,
        value: response,
      });

      dispatch({
        type: TOGGLE_REPORT_LODER,
        value: false,
      });

      // FOR PDF REPORT
      dispatch({
        type: SET_REPORT_DATA_FOR_PDF,
        value: {
          report: KEY,
          data: response,
        },
      });
    } catch (error) {
      console.log("Error", error);
    }
  };
};

export const nodeReportCreator = (data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: TOGGLE_REPORT_LODER,
        value: true,
      });

      const response = await NodeReportsRequest(
        "/create",
        "POST",
        JSON.stringify(data),
        true
      );

      if (response) {
        const KEY = REPORTS_LIST_KEYS[data.reportID.toString()];
        dispatch({
          type: KEY,
          value: response,
        });

        // FOR PDF REPORT
        dispatch({
          type: SET_REPORT_DATA_FOR_PDF,
          value: {
            report: KEY,
            data: response,
          },
        });
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      dispatch({
        type: TOGGLE_REPORT_LODER,
        value: false,
      });
    }
  };
};

export const setPDFReport = (reportData) => {
  return async (dispatch) => {
    dispatch({
      type: SET_REPORT_DATA_FOR_PDF,
      value: reportData,
    });
  };
};

export const setShowMap = (value) => {
  return async (dispatch) => {
    dispatch({
      type: SET_SHOW_MAP,
      value: value,
    });
  };
};

export const setReportData = (value) => {
  return async (dispatch) => {
    dispatch({
      type: SET_REPORT_DATA,
      value: value,
    });
  };
};

export const setActiveIdling = (value) => {
  return async (dispatch) => {
    dispatch({
      type: SET_ACTIVE_IDLING,
      value: value,
    });
  };
};

export const setActiveParking = (value) => {
  return async (dispatch) => {
    dispatch({
      type: SET_ACTIVE_PARKING,
      value: value,
    });
  };
};
