import React, { useState, useEffect } from "react";
import DragableMap from "../../UI/DragableMap";
import StaticMap from "../../UI/StaticMap";
import ChangeStatusModal from "../../modals/task-manager/ChangetatusModal";
import AssignTaskModal from "../../modals/task-manager/AssignTaskModal";
import EditTaskModal from "../../modals/task-manager/EditTaskModal";
import StatusButton from "./StatusButton";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTask,
  getDriverTaskStatusCount,
  getTasks,
} from "../../../store/actions/taskManager.action";
import moment from "moment";
import TaskManagerRequest from "../../../services/TaskManagerRequest";
import { ShowNotification } from "../../../services/Notifications";
import DriverFields from "../FieldsCard/DriverFields";
import TaskFields from "../FieldsCard/TaskFields";
import TaskAttributeFields from "../FieldsCard/TaskAttributeFields";
import CustomerField from "../FieldsCard/CustomerField";
import { isArray } from "underscore";
import DeleteTaskModal from "./DeleteTaskModal";
import { AiFillCloseCircle } from "react-icons/ai";
import { getStorage } from "../../../services/localStorageService";

const ActiveTaskCard = ({
  task,
  isAssigned,
  toggleChatWindow,
  showFields,
  setActiveTaskID,
  statusList,
  statusLocationsList,
  onDeleteTask = null,
  onEditTask = null,
  onChangePriority = null,
  hideDriverMapInfoWindow = null,
  isFromTimeline = false,
}) => {
  const [changeStatus, setChangeStatus] = useState(false);
  const [assignTask, setAssignTask] = useState(false);

  const taskFields = task ? Object.keys(task) : [];

  const statusChangedByList =
    showFields && isArray(showFields?.taskStatus)
      ? showFields.taskStatus.map((s) => `${s.label.toLowerCase()} by`)
      : [];

  if (task && task.startLocation && task.endLocation) {
    task.dropAndPickup = true;
  }

  const toggleChangeStatusModal = () => setChangeStatus(!changeStatus);
  const toggleAssignTaskModal = () => setAssignTask(!assignTask);

  const getStatusTime = (field) => {
    if (task.statusActivities && isArray(task.statusActivities)) {
      var reversedArray = [...task.statusActivities].reverse();

      const exist = reversedArray.findIndex(
        (t) => `${t.status.toLowerCase()} time` == field.label.toLowerCase()
      );
      return exist >= 0
        ? moment(reversedArray[exist].date).format("DD/MM/yy HH:mm")
        : "-";
    } else {
      return "-";
    }
  };

  const getStatusChangedBy = (field) => {
    if (task.statusActivities && isArray(task.statusActivities)) {
      var reversedArray = [...task.statusActivities].reverse();

      const exist = reversedArray.findIndex(
        (t) => `${t.status.toLowerCase()} by` == field.label.toLowerCase()
      );
      return exist >= 0 && reversedArray[exist].user
        ? reversedArray[exist].user
        : "-";
    } else {
      return "-";
    }
  };

  const getStatusLocation = (field, fieldKey) => {
    if (task.statusActivities && isArray(task.statusActivities)) {
      var reversedArray = [...task.statusActivities].reverse();
      const exist = reversedArray.findIndex(
        (t) => `${t.status.toLowerCase()} location` == field.label.toLowerCase()
      );

      if (
        exist >= 0 &&
        reversedArray[exist].location &&
        reversedArray[exist].location[fieldKey]
      ) {
        return reversedArray[exist].location[fieldKey];
      } else {
        fieldKey == "address" ? "-" : {};
      }
    } else {
      return fieldKey == "address" ? "-" : {};
    }
  };

  return (
    <React.Fragment>
      {task && (
        <div className="p-0">
          <ChangeStatusModal
            task={task}
            show={changeStatus}
            toggleModal={toggleChangeStatusModal}
          />
          <AssignTaskModal
            task={task._id}
            show={assignTask}
            toggleModal={toggleAssignTaskModal}
          />

          {/* Header */}
          <Header
            number={task.number}
            setActiveTaskID={setActiveTaskID}
            toggleChatWindow={toggleChatWindow}
            task={task}
            id={task._id}
            status={isAssigned}
            toggleAssignTaskModal={toggleAssignTaskModal}
            onDeleteTask={onDeleteTask}
            onEditTask={onEditTask}
            onChangePriority={onChangePriority}
            hideDriverMapInfoWindow={hideDriverMapInfoWindow}
            isFromTimeline={isFromTimeline}
          />

          {/* DEFAULT FIELDS */}
          {!showFields ||
            (showFields == null && (
              <React.Fragment>
                <div className="row border-bottom-theme1 pt-2 pb-3">
                  <div className="col-md-6">
                    <p style={styles.grayHead}>Date & Time</p>
                    <p className="mb-0 font-12 text-black">
                      {moment(task.taskDate).format("DD-MM-YYYY")} -{" "}
                      {task.fromTime}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p style={styles.grayHead}>Status</p>
                    <StatusButton
                      status={task.taskStatus}
                      statusList={showFields.taskStatus}
                      toggleChangeStatusModal={toggleChangeStatusModal}
                    />
                  </div>
                </div>

                <div className="mb-2 mt-3">
                  <p style={styles.grayHead}>Customer</p>
                  <p className="mb-0 font-12 text-black">
                    {task.customer ? task.customer.name : ""}
                  </p>
                </div>

                <div className="mb-2">
                  <p style={styles.grayHead}>Email</p>
                  <p className="mb-0 font-12 text-black">
                    {task.customer ? task.customer.email : ""}
                  </p>
                </div>

                <div className="mb-2">
                  <p style={styles.grayHead}>Contact</p>
                  <p className="mb-0 font-12 text-black">
                    {task.customer ? task.customer.phoneNumber : ""}
                  </p>
                </div>

                <div className="mb-2">
                  <p style={styles.grayHead}>Start Address</p>
                  <p className="mb-0 font-12 text-black">
                    {task.customer ? task.customer.address : ""}
                  </p>
                </div>

                <div style={{ height: "200px", width: "105%" }}>
                  {task && task.endLocation && (
                    <StaticMap
                      lat={task.endLocation.latitude}
                      lng={task.endLocation.longitude}
                    />
                  )}
                </div>
              </React.Fragment>
            ))}

          {/* ALLOW FIELDS  */}

          {showFields && showFields !== null && (
            <React.Fragment>
              {showFields.details.map((field, i) => {
                return (
                  <div key={i} className="my-2">
                    <p style={styles.grayHead}>{field.label}</p>

                    {/* TASK ACTIVITIES */}
                    {statusList.includes(field.key.toLowerCase()) && (
                      <p className="mb-0 font-12 text-black">
                        {getStatusTime(field)}
                      </p>
                    )}

                    {/* TASK ACTIVITIES */}
                    {statusChangedByList.includes(
                      field.label.toLowerCase()
                    ) && (
                      <p className="mb-0 font-12 text-black">
                        {getStatusChangedBy(field)}
                      </p>
                    )}

                    {/* TASK ACTIVITIES */}
                    {statusList.includes(field.key.toLowerCase()) && (
                      <p className="mb-0 font-12 text-black">
                        {getStatusLocation(field)}
                      </p>
                    )}

                    {/* FROM TASK FIELDS */}
                    {field.type != "populate" && task[field.key] && (
                      <TaskFields field={field} task={task} />
                    )}

                    {/* FOR TIME */}
                    {field.type == "time" && (
                      <small className="task__stats__val font-13">
                        {task?.fromTime}-{task?.toTime}
                      </small>
                    )}

                    {/* FOR TIME */}
                    {field.type == "review" &&
                      task.userRatings &&
                      isArray(task.userRatings) &&
                      task.userRatings.map((rating) => {
                        return (
                          <>
                            <div className="task__stats__val  d-flex font-14">
                              <b className="mr-1">{rating.name}</b>
                              <span class="text-theme">[{rating.ratings}]</span>
                            </div>
                            <span className="font-13 text-secondary">
                              {rating.review}
                            </span>
                            <hr
                              style={{
                                height: 0.5,
                                color: "#f2f2f2",
                                margin: 3,
                              }}
                            />
                          </>
                        );
                      })}

                    {/* FROM DRIVER FIELDS  */}
                    {/* {task.driver && <DriverFields field={field} driver={task.driver} /> } */}

                    {/* FROM CUSTOMER FIELDS  */}
                    {/* {task.driver && <CustomerField field={field} customer={task.customer} /> } */}
                    {task.customer &&
                      field.key == "customer" &&
                      field.type == "populate" && (
                        <CustomerField
                          field={field}
                          isMap={true}
                          coordinates={task.coordinates}
                          customer={task.customer}
                        />
                      )}

                    {/* LOCATIONS  */}
                    {statusLocationsList.includes(field.key) && (
                      <>
                        <p className="mb-0 font-12 text-black">
                          {getStatusLocation(field, "address")}
                        </p>
                        {getStatusLocation(field, "location")?.latitude &&
                          getStatusLocation(field, "location")?.longitude && (
                            <div
                              className="mb-3"
                              style={{
                                height: "200px",
                                width: "105%",
                                marginTop: 10,
                              }}
                            >
                              <StaticMap
                                lat={
                                  getStatusLocation(field, "location")?.latitude
                                }
                                lng={
                                  getStatusLocation(field, "location")
                                    ?.longitude
                                }
                              />
                            </div>
                          )}
                      </>
                    )}

                    {/* FROM ATTRIBUTES */}

                    {task &&
                      task.attributes &&
                      (task.attributes[field.label] ||
                        field.type.toLowerCase() == "checkbox") && (
                        <TaskAttributeFields
                          field={field}
                          attributes={task.attributes}
                        />
                      )}
                  </div>
                );
              })}
            </React.Fragment>
          )}

          {/* {task.attributes &&
                    Object.keys(task.attributes).map((key) => {
                        return <div className="mb-2">
                            <p style={styles.grayHead}>{key}</p>
                            <p className="mb-0 font-12 text-black">{task.attributes[key]}</p>
                        </div>
                    })
                }
 */}
        </div>
      )}
      {/* {!task && 
                <img src={require('../../../assets/img/task_manager_icons/task-temp-image.png').default} width="100%" />
            } */}
    </React.Fragment>
  );
};

const Header = ({
  id,
  status,
  toggleChatWindow,
  toggleAssignTaskModal,
  number,
  task,
  setActiveTaskID,
  onDeleteTask,
  onEditTask,
  onChangePriority,
  hideDriverMapInfoWindow,
  isFromTimeline = false,
}) => {
  const dispatch = useDispatch();
  var driversLastEstimatedTimeData = JSON.parse(
    localStorage.getItem("driversLastEstimatedTimeData")
  );
  const activeDriver = useSelector((state) => state.taskReducer.activeDriver);
  const taskHeaderSwitchOn = useSelector(
    (state) => state.taskReducer.taskHeaderSwitchOn
  );
  const selectedDrivers = useSelector(
    (state) => state.taskReducer.selectedDrivers
  );
  const selectedGroups = useSelector(
    (state) => state.taskReducer.selectedGroups
  );
  const time = useSelector((state) => state.taskReducer.time);
  const [editTask, setEditTask] = useState(false);
  const [showPriority, setShowPriority] = useState(false);

  const [isDeleteModal, setDeleteModal] = useState(false);
  const toggleDeleteModal = () => setDeleteModal(!isDeleteModal);

  const loadTaskSettings = async () => {
    const s = await TaskManagerRequest("/task/settings");
    if (s) {
      setShowPriority(
        s.allowFields && s.allowFields.priority == 1 ? true : false
      );
    }
  };

  useEffect(() => {
    loadTaskSettings();
  }, []);

  const toggleEditTaskModal = () => {
    if (hideDriverMapInfoWindow) hideDriverMapInfoWindow();
    setEditTask(!editTask);
  };

  const deleteTaskHandler = async (isRemove = false) => {
    if (!window.confirm("Are you sure you want to delete this tasks?")) return;

    try {
      var __data = { task: id, isRemoveRepeatTask: isRemove };

      const response = await TaskManagerRequest(`/delete/task`, __data);
      ShowNotification("success", "Task deleted successfully");
      setActiveTaskID(-1);
      setDeleteModal(false);

      var _drivers = activeDriver ? [activeDriver._id] : selectedDrivers;

      dispatch(
        getTasks({
          startDate: `${moment(time.from).format("yyyy-MM-DD")} 00:00:00`,
          endDate: `${moment(time.to).format("yyyy-MM-DD")} 23:59:59`,
          driver: taskHeaderSwitchOn ? _drivers : null,
          group: taskHeaderSwitchOn ? selectedGroups : null,
          taskStatus: time.taskStatus == "All" ? false : time.taskStatus,
        })
      );
      dispatch(
        getDriverTaskStatusCount({
          startDate: `${moment(time.from).format("yyyy-MM-DD")} 00:00:00`,
          endDate: `${moment(time.to).format("yyyy-MM-DD")} 23:59:59`,
        })
      );
      if (onDeleteTask) onDeleteTask();
    } catch (error) {
      ShowNotification("error", error);
    }
  };

  const changePriorityHandler = async () => {
    const data = {
      ...task,
      task: task._id,
      priority: task.priority == "critical" ? "normal" : "critical",
    };

    const user = await getStorage("__user__", "object");
    if (user) {
      data.authUserName = user.name;
    }

    try {
      const response = await TaskManagerRequest("/update/driver/task", data);
      var _drivers = activeDriver ? [activeDriver._id] : selectedDrivers;

      dispatch(
        getTasks({
          startDate: `${moment(time.from).format("yyyy-MM-DD")} 00:00:00`,
          endDate: `${moment(time.to).format("yyyy-MM-DD")} 23:59:59`,
          driver: taskHeaderSwitchOn ? _drivers : null,
          group: taskHeaderSwitchOn ? selectedGroups : null,
          taskStatus: time.taskStatus == "All" ? false : time.taskStatus,
        })
      );
      if (onChangePriority) {
        onChangePriority(response);
      }
      ShowNotification("success", "Priority changed successfully");
    } catch (error) {
      ShowNotification("error", error);
    }
  };

  return (
    <React.Fragment>
      <EditTaskModal
        onEditTask={onEditTask}
        data={id}
        show={editTask}
        task={task}
        toggleModal={toggleEditTaskModal}
        isFromTimeline={isFromTimeline}
      />
      <DeleteTaskModal
        show={isDeleteModal}
        toggleModal={toggleDeleteModal}
        onRemove={deleteTaskHandler}
      />

      <div className="row mx-0 border-bottom-theme1 pb-1">
        <div className="col-md-8 p-0 my-auto">
          {/* <p className="font-11 mb-0">Task ID: #{number}</p> */}
          <p className="font-11 mb-0">
            <span style={styles.grayHead}>Created by : </span>{" "}
            {task.createdBy ? task.createdBy.name : "-"}
          </p>
          <p className="font-11 mb-0">
            <span style={styles.grayHead}>Staff : </span>{" "}
            {task.driver ? task.driver.name : "-"}
          </p>
          <p className="font-11 mb-0">
            <span style={styles.grayHead}>Estimated Time : </span>{" "}
            {task.driver &&
            driversLastEstimatedTimeData &&
            driversLastEstimatedTimeData[task.driver._id] &&
            driversLastEstimatedTimeData[task.driver._id][task._id]
              ? driversLastEstimatedTimeData[task.driver._id][task._id]
              : "-"}
          </p>
        </div>
        <div className="col-md-4 pr-0 text-end">
          {(!task.driver || task.driver == null) && (
            <button
              onClick={toggleAssignTaskModal}
              className="btn btn-assign-tasks mb-1 font-10 p-1 pl-2 pr-2"
            >
              Assign
            </button>
          )}
          <div className="d-flex float-right">
            <div
              className="edit-container pointer mr-1 mt-0"
              onClick={toggleEditTaskModal}
            ></div>
            <div
              className="delete-container pointer mr-1"
              onClick={
                task?.hasRepeatTask
                  ? toggleDeleteModal
                  : () => deleteTaskHandler(false)
              }
            ></div>
            {task && task.priority && task.priority == "critical"
              ? showPriority && (
                  <div
                    className="star-container pointer mr-1"
                    onClick={changePriorityHandler}
                  ></div>
                )
              : showPriority && (
                  <div
                    className="star-outline-container pointer mr-1"
                    onClick={changePriorityHandler}
                  >
                    <img
                      src={
                        require("../../../assets/img/task_manager_icons/star-outline.png")
                          .default
                      }
                      height="20"
                      width="20"
                    />
                  </div>
                )}

            {(task.driver || task.driver != null) && (
              <span onClick={toggleChatWindow} style={styles.chatIcon}>
                <img
                  src={
                    require("../../../assets/img/task_manager_icons/messenger.png")
                      .default
                  }
                  height="16"
                  width="16"
                />
              </span>
            )}
            <AiFillCloseCircle
              size={26}
              onClick={() => setActiveTaskID(-1)}
              className="ml-1 pointer"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const styles = {
  grayHead: {
    color: "gray",
    fontSize: "12px",
    marginBottom: "0px",
  },
  chatIcon: {
    backgroundColor: "#c0ffc0",
    borderRadius: "50%",
    padding: "1px 5px 1px 5px",
    marginLeft: "5px",
    cursor: "pointer",
  },
};

export default ActiveTaskCard;
