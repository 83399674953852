import React, { useEffect, useState, useRef } from "react";
import Default from "../../../../pages/Default";

import FullCalendar from "@fullcalendar/react"; // must go before plugins
import resourceTimelinePlugin from "@fullcalendar/resource-timeline"; // a plugin!
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";

import "./timeline.css";
import TaskManagerRequest from "../../../../services/TaskManagerRequest";
import LottieLoader from "../../../UI/LottieLoader";
import moment from "moment/moment";

import ReactDOMServer from "react-dom/server";
import Input from "../../../UI/Input";
import $ from "jquery";
import AssignedTaskCard from "../AssignedTaskCard";
import CreateTaskModal from "../../../modals/task-manager/CreateTaskModal";
import ActiveTaskCard from "../ActiveTaskCard";
import ChatWindow from "../ChatWindow/ChatWindow";
import Modal from "react-bootstrap/Modal";
import LocationPage from "../../location/locationsPage";
import Task from "../../Task";
import { useDispatch, useSelector } from "react-redux";
import _getCurrentLocation from "../../../../configs/current-location";
import { ShowNotification } from "../../../../services/Notifications";
import SearchInput from "../../../SearchInput";
import _ from "underscore";
import {
  getDriverTaskStatusCount,
  setTimeLineView,
} from "../../../../store/actions/taskManager.action";
import TimelineTimeSettingsModal from "../../../settings/times/TimelineTimeSettingsModal";
import {
  getStatusCount,
  setDriversLastEstimatedTimeData,
  unSetDriversLastEstimatedTimeData,
} from "../../../../configs/common";
import { toHHmmFromSeconds } from "../../../reports/ReportFilter";
import { getStorage } from "../../../../services/localStorageService";

const Demo = () => {
  return <Input />;
};

const TimelineView = () => {
  const dispatch = useDispatch();
  const calenderRef = useRef();

  const _driverTaskStatusCount = useSelector(
    (state) => state.taskReducer.driverTaskStatusCount
  );
  const _time = useSelector((state) => state.taskReducer.time);

  const _officeTime = useSelector((state) => state.taskReducer.officeTime);

  const [isLoading, setLoading] = useState(false);
  const [isLoadingTimelineData, setLoadingTimelineData] = useState(false);

  const [timeline, setTimeline] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [filteredDrivers, setFilteredDrivers] = useState([]);
  const [taskFieldsSettings, setTaskFieldsSetting] = useState(false);
  const [createTaskModal, setCreateTaskModal] = useState(false);
  const [activeTask, setActiveTask] = useState(-1);
  const [isChatWindow, setIsChatWindow] = useState(false);
  const [isLocationModal, setIsLocationModal] = useState(false);
  const [isSearchOn, setIsSearchOn] = useState("");

  const [selectedDate, setSelectDate] = useState(new Date());
  const [activeDriver, setActiveDriver] = useState(false);
  const [fromTime, setFromTime] = useState(new Date());

  const [isTimelineTimeModal, setTimelineTimeModal] = useState(false);

  const [slotDuration, setSlotDuration] = useState(10);
  const [slotDurationInDuration, setSlotDurationInDuration] =
    useState("00:15:00");

  const [optimizationNeededDrivers, setOptimizationNeededDrivers] = useState(null);
  const [isOptimizationNeeded, setIsOptimizationNeeded] = useState(false);

  const toggleChatWindow = (value) => setIsChatWindow(value);
  const toggleCreateTaskModal = () => {
    if (createTaskModal) setActiveDriver(false);
    setCreateTaskModal(!createTaskModal);
  };
  const toggleLocationModal = () => setIsLocationModal(!isLocationModal);

  const updateTimeLineTask = (taskId, data) => {
    try {
      var clone = { ...timeline };
      const exist = clone.tasks.findIndex(
        (taskData) => taskData.task?._id == taskId
      );
      if (exist != -1) {
        clone.tasks[exist].task = { ...clone.tasks[exist].task, ...data };
        clone.tasks[exist].title = "changed...";
      }
      console.log("exist...", clone);
      setTimeline(clone);
    } catch (error) {}
  };

  const handleEventDrop = async (info) => {
    try {
      $(".tooltipevent").remove();
      setLoading(true);
      var start = new Date(info.event._instance.range.start);
      var utcStart = new Date(
        start.getTime() + start.getTimezoneOffset() * 60000
      );

      var end = new Date(info.event._instance.range.end);
      var utcEnd = new Date(end.getTime() + end.getTimezoneOffset() * 60000);

      var driver = info.newResource?._resource.id;
      var task = info.event._def.extendedProps?.task;

      var timeDifferenceInMilliseconds = utcEnd - utcStart;
      var timeDifferenceInMinutes = Math.floor(
        timeDifferenceInMilliseconds / (60 * 1000)
      );

      if (task) {
        var data = {
          // task: task._id,
          EstimatedFromTime: moment(utcStart).format("HH:mm"),
          EstimatedToTime: moment(utcEnd).format("HH:mm"),
          jobWaitingTime: timeDifferenceInMinutes,
        };
        if (driver) {
          data.driver = driver;
          unSetDriversLastEstimatedTimeData(driver);
        }
        if (task.driver) {
          unSetDriversLastEstimatedTimeData(task.driver._id);
        }

        var _location = await _getCurrentLocation(false, true);
        if (_location) data.location = _location;
        // updateTimeLineTask(task._id, data);

        const user = await getStorage("__user__", "object");
        if (user) {
          data.authUserName = user.name;
        }

        data.task = task._id;
        try {
          const response = await TaskManagerRequest(
            "/update/driver/task",
            data
          );
        } catch (error) {
          ShowNotification(
            "Error",
            "Oops! Failed to update task. Please try again"
          );
        } finally {
          loadTimeLineData();
        }
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const loadTimeLineData = async () => {
    try {
      const timelineResponse = await TaskManagerRequest("/drivers/timeline", {
        date: moment(selectedDate)
          .add(selectedDate.getTimezoneOffset() / 60, "hours")
          .format("MM-DD-YYYY"),
      });
      setTimeline(timelineResponse);

      dispatch(
        getDriverTaskStatusCount({
          startDate: `${moment(selectedDate).format("yyyy-MM-DD")} 00:00:00`,
          endDate: `${moment(selectedDate).format("yyyy-MM-DD")} 23:59:59`,
        })
      );
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const loadTimeLineDrivers = async () => {
    try {
      const response = await TaskManagerRequest("/timeline/drivers", {});
      setDrivers(response);
    } catch (error) {
      console.log("error", error);
    }
  };

  const loadSettings = async () => {
    try {
      const response = await TaskManagerRequest(`/task/settings`, {});
      setTaskFieldsSetting(response);
    } catch (error) {
      console.log("Error...", error);
    }
  };

  const handleEventResize = (info) => {
    var tis = info.el;
    var tooltipData = "";

    var start = new Date(info.event._instance.range.start);
    var utcStart = new Date(
      start.getTime() + start.getTimezoneOffset() * 60000
    );

    var end = new Date(info.event._instance.range.end);
    var utcEnd = new Date(end.getTime() + end.getTimezoneOffset() * 60000);

    $(".tooltipevent").remove();
    if (info.event._def.extendedProps && info.event._def.extendedProps.task) {
      tooltipData = `<span>Resizing Event ${moment(utcStart).format(
        "HH:mm"
      )} - ${moment(utcEnd).format("HH:mm")}<span/>`;
    }
    var tooltip =
      '<div style="top:' +
      ($(tis).offset().top - 40) +
      "px;left:" +
      ($(tis).offset().left + $(tis).width() / 2) +
      'px" class="tooltForResize">' +
      `${tooltipData}` +
      "</div>";

    $(tooltip).appendTo("body");
  };

  const handleEventResizeEnd = (info) => {
    $(".tooltForResize").remove();
  };

  const handleZoom = (type) => {
    const MINUTES_SLOTS = ["10", "30", "40", "50"];
    var currentType = slotDurationInDuration.split(":"); // in Hour or Minutes

    if (type == "+") {
      // ZOOM IN

      // MINUTES_SLOTS
      if (currentType[0] == "00") {
        var currentSlotIndex = MINUTES_SLOTS.indexOf(currentType[1]);
        if (currentSlotIndex == 0) return false;
        setSlotDurationInDuration(
          `00:${MINUTES_SLOTS[currentSlotIndex - 1]}:00`
        );
      } else {
        if (Number(currentType[0]) <= 1) {
          // MINUTES_SLOTS
          setSlotDurationInDuration(`00:50:00`);
        } else {
          // HOURS_SLOTS
          setSlotDurationInDuration(`0${Number(currentType[0]) - 1}:00:00`);
        }
      }
    } else {
      // ZOOM OUT
      if (currentType[0] == "00") {
        var currentSlotIndex = MINUTES_SLOTS.indexOf(currentType[1]);
        if (currentSlotIndex == 3) {
          setSlotDurationInDuration(`01:00:00`);
          return false;
        }
        setSlotDurationInDuration(
          `00:${MINUTES_SLOTS[currentSlotIndex + 1]}:00`
        );
      } else {
        if (Number(currentType[0]) >= 10) {
          return false;
        }
        // HOURS_SLOTS
        setSlotDurationInDuration(`0${Number(currentType[0]) + 1}:00:00`);
      }
    }
  };

  const _getDurationFromTitle = (title) => {
    try {
      var t = title.split("[");
      if (t.length == 0) return false;

      return t[t.length - 1].split("]")[0];
    } catch (e) {
      return false;
    }
  };

  const checkOptimizationRequired = () => {
    const tasks = timeline?.tasks;
    const optimizeRequiredDrivers = [];
    const groupedTasks = tasks?.reduce((acc, task) => {
      if (acc[task.resourceId]) {
        acc[task.resourceId]++;
      } else {
        acc[task.resourceId] = 1;
      }
      return acc;
    }, {});

    Object?.keys(groupedTasks || {})?.forEach((task) => {
      const totalDriverTasks = getStatusCount(
        task,
        "totalTask",
        _driverTaskStatusCount
      );

      if (totalDriverTasks !== groupedTasks[task]) {
        const driver = filteredDrivers?.find((driver) => driver?.id === task);
        optimizeRequiredDrivers.push(driver?.title);
      }
    });

    if (optimizeRequiredDrivers?.length > 0) {
      setOptimizationNeededDrivers(optimizeRequiredDrivers);
      setIsOptimizationNeeded(true);
    } else {
      setOptimizationNeededDrivers(false);
      setIsOptimizationNeeded(false);
    }
  };

  useEffect(() => {
    checkOptimizationRequired();
  }, [timeline?.tasks, _driverTaskStatusCount]);

  useEffect(() => {
    loadTimeLineDrivers();
    loadSettings();
    loadTimeLineData();
  }, []);

  useEffect(() => {
    loadTimeLineData();
  }, [selectedDate]);

  useEffect(() => {
    var clone = [...drivers];
    if (isSearchOn != "") {
      clone = _.filter(clone, (item) =>
        item.title?.match(new RegExp(isSearchOn, "gi"))
      );
    }
    setFilteredDrivers(clone);
  }, [isSearchOn, drivers]);

  const toggleOfficeTimeModal = () =>
    setTimelineTimeModal(!isTimelineTimeModal);

  // if (!timeline) return <LottieLoader />

  const customEventContent = ({ event }) => {
    const totalJobTime = moment(event.end).diff(moment(event.start), "seconds");
    const actualTravelTime = event._def.extendedProps.task.travelingTime;
    const travelTimePercentage = (actualTravelTime * 100) / totalJobTime;

    const actualWaitingTime =
      event._def.extendedProps.task.EstimatedWaitingTime;
    const waitingTimePercentage = (actualWaitingTime * 100) / totalJobTime;

    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {travelTimePercentage == 0 ? (
          <></>
        ) : (
          <div
            className="event-job-travel-time-wrapper"
            title={`Travel Time : [${toHHmmFromSeconds(
              actualTravelTime,
              "HH:mm"
            )}]`}
            style={{
              backgroundColor: "#16169d",
              color: "white",
              width: `${travelTimePercentage}%`,
            }}
          >
            <strong className="event-job-actual-time">
              Travel Time :{" "}
              {`[${toHHmmFromSeconds(actualTravelTime, "HH:mm")}]`}
            </strong>
          </div>
        )}
        {actualWaitingTime == 0 ? (
          <></>
        ) : (
          <div
            className="event-job-travel-time-wrapper"
            title={`Waiting Time : [${toHHmmFromSeconds(
              actualWaitingTime,
              "HH:mm"
            )}]`}
            style={{
              backgroundColor: "#7d7dff",
              color: "white",
              width: `${waitingTimePercentage}%`,
            }}
          >
            <strong className="event-job-actual-time">
              Waiting Time :{" "}
              {`[${toHHmmFromSeconds(actualWaitingTime, "HH:mm")}]`}
            </strong>
          </div>
        )}
        <div
          className="event-job-actual-time-wrapper"
          style={{ backgroundColor: "blue", color: "white", flex: 1 }}
        >
          <strong className="event-job-actual-time">{event.title}</strong>
        </div>
      </div>
    );
  };

  // console.log("_officeTime........", _officeTime);
  // console.log("_time........", _time);

  return (
    <Default max100={true}>
      {isLoading && (
        <div
          className={`loader-position`}
          style={{
            left: 0,
            top: 0,
            paddingLeft: "35%",
            paddingTop: "48vh",
            height: "100vh",
            width: "100vw",
          }}
        >
          <LottieLoader />
        </div>
      )}

      <TimelineTimeSettingsModal
        show={isTimelineTimeModal}
        toggleModal={toggleOfficeTimeModal}
      />

      <CreateTaskModal
        show={createTaskModal}
        status={"Assigned"}
        toggleModal={toggleCreateTaskModal}
        onCreateTask={loadTimeLineData}
        activeDriver={activeDriver}
        fromTime={fromTime}
      />

      <Modal
        show={isLocationModal}
        dialogClassName="modal-70w"
        onHide={toggleLocationModal}
        aria-labelledby="attendanceMap"
      >
        {/* <Modal.Header closeButton >
                    Locations
                </Modal.Header> */}
        <Modal.Body className="show-grid">
          <div style={{ height: "87vh", overflow: "hidden" }}>
            <LocationPage toggleLocation={toggleLocationModal} />
          </div>
        </Modal.Body>
      </Modal>

      {activeTask != -1 && (
        <div
          className={`col-md-4 visible-scroll-hover ${
            isChatWindow
              ? "bg-chat pl-2 chat-box-shadow"
              : "bg-white chat-box-shadow"
          }`}
          style={styles.container}
        >
          {isChatWindow ? (
            <ChatWindow
              toggleChatWindow={() => toggleChatWindow(false)}
              isChatWindow={isChatWindow}
              task={activeTask}
              setLoading={setLoading}
              isLoading={isLoading}
            />
          ) : (
            <ActiveTaskCard
              isFromTimeline={true}
              task={activeTask}
              onDeleteTask={loadTimeLineData}
              onEditTask={() => {
                loadTimeLineData();
                setActiveTask(-1);
              }}
              onChangePriority={(data) => {
                loadTimeLineData();
                setActiveTask(data);
              }}
              isAssigned={false}
              showFields={taskFieldsSettings}
              toggleChatWindow={() => toggleChatWindow(true)}
              setActiveTaskID={setActiveTask}
              statusList={
                taskFieldsSettings && taskFieldsSettings.taskStatus
                  ? taskFieldsSettings.taskStatus.map((s) =>
                      s.type.toLowerCase()
                    )
                  : []
              }
              statusLocationsList={
                taskFieldsSettings && taskFieldsSettings.taskStatus
                  ? taskFieldsSettings.taskStatus.map(
                      (s) => `${s.type}_location`
                    )
                  : []
              }
            />
          )}
        </div>
      )}

      <div className="relative p-2">
        {isOptimizationNeeded && optimizationNeededDrivers?.length > 0 && (
          <div className="card p-2 d-flex flex-column" style={{position : 'absolute', left : "50%", top: "50%", zIndex : 9999}}>
            <div className="d-flex justify-content-between gap-3">
             <span className="font-600 font-12">New tasks created. The following driver(s) require optimization.</span>
             <span className="pointer font-14 font-600" onClick={() => setIsOptimizationNeeded(false)}>X</span>
            </div>
            <div className="text-wrap">
            {optimizationNeededDrivers?.map((driver) => (
              <span className="font-11">{driver},{" "}</span>
            ))}
            </div>
          </div>
        )}

        {/* 
                    1. START TIME FROM SETTINGS 
                    2.ON event drag start show Duration and start - end time.
                    3. Cutoff popup
                */}
        <FullCalendar
          ref={calenderRef}
          nowIndicator={true}
          slotDuration={slotDurationInDuration}
          timeZone="IST"
          stickyFooterScrollbar={true}
          editable={false}
          eventStartEditable={false}
          droppable={false}
          // eventResourceEditable={true}
          // eventDurationEditable={true}
          aspectRatio={10}
          initialEvents={timeline?.tasks || []}
          events={timeline?.tasks || []}
          scrollTimeReset={true}
          scrollTime={
            _officeTime.officeStartTime == "Invalid date"
              ? "00:00"
              : _officeTime.officeStartTime
          }
          // eventResize={handleEventDrop}
          slotMinTime={
            _officeTime.officeStartTime == "Invalid date"
              ? "00:00"
              : _officeTime.officeStartTime
          }
          slotMaxTime={
            _officeTime.officeEndTime == "Invalid date"
              ? "23:59"
              : _officeTime.officeEndTime
          }
          eventRender={function (info) {
            var tooltip = new Tooltip(info.el, {
              title: "XXX",
              placement: "top",
              trigger: "hover",
              container: "body",
            });
          }}
          eventContent={customEventContent}
          headerToolbar={{
            left: "title prev,next today zoomIn zoomOut",
            // center: 'createTask locations',
            right: "createTask locations timelineTime close",
          }}
          titleFormat={{ year: "numeric", month: "short", day: "numeric" }}
          customButtons={{
            zoomIn: {
              text: "+",
              click: function () {
                handleZoom("+");
              },
            },
            zoomOut: {
              text: "-",
              click: function () {
                handleZoom("-");
              },
            },
            createTask: {
              text: "Create Task",
              click: function () {
                setFromTime(new Date());
                toggleCreateTaskModal();
              },
            },
            locations: {
              text: "Locations",
              click: function () {
                toggleLocationModal();
              },
            },
            dateTitle: {
              text: moment().format("LLL"),
              className: "title",
            },
            timelineTime: {
              text: "Time Settings",
              click: function () {
                toggleOfficeTimeModal();
              },
            },
            close: {
              text: "close",
              click: function () {
                dispatch(setTimeLineView(false));
              },
            },
            today: {
              text: "Today",
            },
          }}
          // date={selectedDate}
          dateClick={(info) => {
            var start = new Date(info.dateStr);
            setFromTime(start);
            setActiveDriver(info.resource._resource.extendedProps.driver);
            toggleCreateTaskModal();
          }}
          datesSet={(dateInfo) => {
            setSelectDate(dateInfo.start);
          }}
          initialDate={
            _time.from?.isSame(_time.to)
              ? _time.from.format("YYYY-MM-DD")
              : new Date().toISOString().split("T")[0]
          }
          eventClick={(args) => {
            if (
              args.event._def.extendedProps &&
              args.event._def.extendedProps.task
            ) {
              setActiveTask(args.event._def.extendedProps.task);
            }
          }}
          eventMouseEnter={(info) => {
            var tis = info.el;
            var tooltipData = "";

            const parentElement = tis.parentNode;
            const computedStyle = window.getComputedStyle(parentElement);
            const rightValue = Number(
              computedStyle.getPropertyValue("right").split("px")[0] || 0
            );

            if (
              info.event._def.extendedProps &&
              info.event._def.extendedProps.task
            ) {
              var task = info.event._def.extendedProps.task;
              var id = 0;
              var exist = timeline.tasks.findIndex(
                (t) => task._id == t.task._id
              );
              if (exist != -1) {
                id = exist;
              }
              tooltipData = ReactDOMServer.renderToString(
                <AssignedTaskCard
                  fromTimeLine={true}
                  cardSettings={
                    taskFieldsSettings ? taskFieldsSettings.card : false
                  }
                  statusList={
                    taskFieldsSettings && taskFieldsSettings.taskStatus
                      ? taskFieldsSettings.taskStatus.map((s) => s.type)
                      : []
                  }
                  showFields={taskFieldsSettings}
                  statusLocationsList={
                    taskFieldsSettings && taskFieldsSettings.taskStatus
                      ? taskFieldsSettings.taskStatus.map(
                          (s) => `${s.type}_location`
                        )
                      : []
                  }
                  isActive={false}
                  key={id}
                  id={id}
                  task={task}
                  // timelineDuration={_getDurationFromTitle(
                  //   info.event._def.title
                  // )}
                  timelineDuration={true}
                  onClick={null}
                  toggleChatWindow={null}
                />
              );
            }
            var tooltip =
              '<div style="top:' +
              ($(tis).offset().top + 5) +
              "px;left:" +
              ($(tis).offset().left +
                (rightValue > -850 ? $(tis).width() / 2 : -250)) +
              'px" class="tooltipevent">' +
              `${tooltipData}` +
              "</div>";

            $(tooltip).appendTo("body");
          }}
          eventMouseLeave={(info) => {
            $(info.el).css("z-index", 8);
            $(".tooltipevent").remove();
          }}
          // eventDrop={handleEventDrop}
          resources={filteredDrivers}
          resourceLabelContent={(info) => {
            return (
              <Task
                statusList={
                  taskFieldsSettings && taskFieldsSettings.taskStatus
                    ? taskFieldsSettings.taskStatus
                    : []
                }
                driverTaskStatusCount={_driverTaskStatusCount}
                setLoading={setLoading}
                isLoading={isLoading}
                disabled={true}
                // onClick={() => {
                //     setActiveDriver(info.resource._resource.extendedProps.driver)
                //     toggleCreateTaskModal()
                // }}
                task={info.resource._resource.extendedProps.driver}
              />
            );
          }}
          resourceAreaWidth={"30%"}
          resourceAreaHeaderContent={(info) => {
            return (
              <div className="d-flex justify-content-between p-2">
                <div className="flex-1">
                  <SearchInput
                    onSearch={(event) => setIsSearchOn(event.target.value)}
                    placeholder="Search........"
                    value={isSearchOn}
                    text={isSearchOn}
                    onClear={() => setIsSearchOn("")}
                    // iconStyle={{ right: 75 }}
                  />
                </div>
                {/* <IconButton
                                        type="button"
                                        label="Today"
                                        buttonstyle={{ color: '#fff', backgroundColor: '#3166ee' }}
                                        onClick={() => setSelectDate(new Date())}
                                    /> */}
              </div>
            );
          }}
          // eventResizeStart={handleEventResize}
          // eventResizeStop={handleEventResizeEnd}
          height={"89vh"}
          initialView="resourceTimelineDay"
          schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
          plugins={[resourceTimelinePlugin, interactionPlugin]}
        />
      </div>
    </Default>
  );
};
const styles = {
  container: {
    height: "91vh",
    overflowY: "auto",
    paddingTop: 10,
    position: "absolute",
    right: 0,
    zIndex: 999,
  },
};
export default React.memo(TimelineView);
