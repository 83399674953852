import React, { useState } from "react";
import ImageModal from "../../UI/ImageModal";
import { TASK_ASSETS_URL } from "../../../environments/environments";

const TaskAttributeFields = (props) => {
  props.field.type = props.field.type.toLowerCase();
  const [imageModal, setImageModal] = useState(false);
  const toggleImageModal = () => setImageModal(!imageModal);

  const parseSignature = (signature) => {
    if (signature.startsWith("data:image/png;base64,")) {
      return signature;
    } else {
      return "data:image/png;base64," + signature;
    }
  };

  switch (props.field.type) {
    case "signature":
      if (
        !props.attributes[props.field.key] ||
        props.attributes[props.field.key] == ""
      )
        return <p className="mb-0 font-12 text-black">-</p>;
      return (
        <img
          className="sign-image-view"
          src={parseSignature(props.attributes[props.field.key])}
          height={100}
          width={"100%"}
        />
      );

    case "image":
      if (
        !props.attributes[props.field.key] ||
        props.attributes[props.field.key] == ""
      )
        return <p className="mb-0 font-12 text-black">-</p>;
      // return <a className='pointer' href={props.attributes[props.field.key]} ><img className="sign-image-view" src={props.attributes[props.field.key]} height={100} width={'100%'} /></a>
      var img = `${TASK_ASSETS_URL}/${props.attributes[props.field.key]}`;
      return (
        <React.Fragment>
          <ImageModal
            show={imageModal}
            toggleModal={toggleImageModal}
            title="Attachments"
            src={img}
          />
          <img
            className="sign-image-view pointer"
            onClick={toggleImageModal}
            src={img}
            height={100}
            width={"100%"}
          />
        </React.Fragment>
      );

    case "checkbox":
      const vals = Object.keys(props.attributes).filter((k) =>
        k.startsWith("checkbox")
      );
      const finalValue = vals
        .filter(
          (k) => props.attributes[k] == "on" || props.attributes[k] == true
        )
        .map((k) => k.split("checkbox_")[1])
        .join(", ");

      return <p className="mb-0 font-12 text-black">{finalValue}</p>;

    default:
      return (
        <p className="mb-0 font-12 text-black">
          {props.attributes[props.field.key]}
        </p>
      );
  }
};

export default TaskAttributeFields;
