import axios from "axios";
import { getStorage } from "./localStorageService";

import $ from "jquery";
import { API_URLS } from "../configs/api.urls";

const API_URL = API_URLS.API_REPORTS_GATEWAY;

const LaravelReportServerRequest = async (path, data = {}, button = false) => {
  const token = getStorage("reports__gateway__token");

  const headers = {
    Xtoken: token,
    "X-Xsrf-TOKEN": token,
    "X-CSRF-TOKEN": token,
    "Content-Type": "application/json",
  };

  return new Promise((resolve, reject) => {
    var buttonText = "";

    if (button) {
      var elem = document.getElementById(button);

      buttonText = elem.textContent || elem.innerText;
      elem.innerHTML = `<i class="fa fa-spinner fa-spin"> </i> Loading...`;
      elem.disabled = true;
    }

    axios
      .post(`${API_URL}${path}`, data, { headers: headers, crossDomain: true })
      .then(function (response) {
        try {
          if (!response.data.responseCode && response.status == 200) {
            resolve(response.data);
            return;
          }

          if (response.data.responseCode == 401 && path != "/api-v1/user/postlogin") {
            // window.location.href = "/login";
            throw new Error("Unauthorized");
          }

          if (response.data.responseCode !== 200) {
            throw new Error(response.data.response);
          }
          resolve(response.data);
        } catch (error) {
          throw new Error(error.message);
        } finally {
          if (button) {
            var elem = document.getElementById(button);
            elem.innerHTML = buttonText;
            elem.disabled = false;
          }
        }
      })
      .catch(function (error) {
        if (button) {
          var elem = document.getElementById(button);
          elem.innerHTML = buttonText;
          elem.disabled = false;
        }
        reject(error.message);
      });
  });
};

export default LaravelReportServerRequest;
