import GetRequest from "../../services/GetRequest";
import PostRequest from "../../services/PostRequest";
import { getStorage, setStorage } from "../../services/localStorageService";
import TaskManagerRequest from "../../services/TaskManagerRequest";

export const SET_GROUPS = "SET_GROUPS";
export const TOGGLE_VEHICLE_CHECKED = "TOGGLE_VEHICLE_CHECKED";
export const TOGGLE_VEHICLE_GROUP_CHECKED = "TOGGLE_VEHICLE_GROUP_CHECKED";
export const GET_SUBUSERS = "GET_SUBUSERS";
export const GET_SUBUSER = "GET_SUBUSER";
export const GET_REPORT_GROUP_NAMES = "GET_REPORT_GROUP_NAMES";
export const GET_REPORT_NAMES = "GET_REPORT_NAMES";
export const TOGGLE_SELECT_REPORT = "TOGGLE_SELECT_REPORT";

// Storage
export const GET_ORGANIZATION_STORAGE = "GET_ORGANIZATION_STORAGE";

// Settings
export const GET_SHARE_LOCATION_SETTINGS = "GET_SHARE_LOCATION_SETTINGS";
export const GET_SINGLE_OBJ_GROUP = "GET_SINGLE_OBJ_GROUP";
// Report Schedular
export const GET_REPORT_SCHEDULERS = "GET_REPORT_SCHEDULERS";
export const GET_REPORT_SCHEDULE = "GET_REPORT_SCHEDULE";
export const GET_SINGLE_REPORT_SCHEDULER = "GET_SINGLE_REPORT_SCHEDULER";
export const SELECT_OBJECT = "SELECT_OBJECT";
export const SELECT_OBJECT_GROUP = "SELECT_OBJECT_GROUP";
// Notifications
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_GEOZONES = "GET_GEOZONES";
export const ON_SELECT_GEOZONE = "ON_SELECT_GEOZONE";
export const ON_SELECT_ALL_GEOZONE = "ON_SELECT_ALL_GEOZONE";
export const GET_SINGLE_NOTIFICATION = "GET_SINGLE_NOTIFICATION";
// Dashboard settings
export const GET_DASHBOARD_SETTINGS = "GET_DASHBOARD_SETTINGS";
export const UPDATE_DASHBOARD_SETTINGS = "UPDATE_DASHBOARD_SETTINGS";
// Task Manager
export const GET_TASK_CHECK_SETTINGS = "GET_TASK_CHECK_SETTINGS";
export const GET_TASK_MAP_SETTINGS = "GET_TASK_MAP_SETTINGS";
export const GET_TASK_REVIEW_SETTINGS = "GET_TASK_REVIEW_SETTINGS";
export const GET_TASK_ATTRIBUTES = "GET_TASK_ATTRIBUTES";
export const GET_REPORT_CONFORMATION_DETAILS =
  "GET_REPORT_CONFORMATION_DETAILS";
export const GET_TASK_SETTINGS = "GET_TASK_SETTINGS";
export const GET_SYSTEM_SETTINGS = "GET_SYSTEM_SETTINGS";
export const GET_TASK_CUSTOMER_GROUPS = "GET_TASK_CUSTOMER_GROUPS";
export const GET_TASK_DRIVER_GROUPS = "GET_TASK_DRIVER_GROUPS";
export const GET_SINGLE_TASK_DRIVER_GROUPS = "GET_SINGLE_TASK_DRIVER_GROUPS";
export const GET_TASK_SUBUSER = "GET_TASK_SUBUSER";
export const GET_TASK_DRIVERS = "GET_TASK_DRIVERS";
export const GET_TASK_DRIVERS_OF_SUBUSER = "GET_TASK_DRIVERS_OF_SUBUSER";

//Inspection
export const GET_INSPECTION_SETTINGS = "GET_INSPECTION_SETTINGS";
export const UPDATE_INSPECTION_SETTINGS = "UPDATE_INSPECTION_SETTINGS";

// Other Settings
export const GET_ORGANIZATION_SETTING = "GET_ORGANIZATION_SETTING";
export const GET_ORGANIZATION_VEHICLES = "GET_ORGANIZATION_VEHICLES";
export const GET_ORGANIZATION_VEHICLES_BASED_ON_KEY =
  "GET_ORGANIZATION_VEHICLES_BASED_ON_KEY";
export const GET_GRAPHICAL_REPORTS = "GET_GRAPHICAL_REPORTS";

// Language switching
export const SWITCH_LANGUAGE = "SWITCH_LANGUAGE";


// AR TO ENGLISH Language translactions
export const leftMenus = {
  Dashboard: "لوحة القيادة",
  Live: "حي",
  Trips: "الرحلات",
  Reports: "التقارير",
  "Fleet Manager": "مدير الأسطول",
  "Expense Manager": "مدير المصاريف",
  Tolls: "الرسوم",
  "Zone & POIs": "المناطق و النقاط المهمة",
  "Task Manager": "مدير المهام",
  "Assets Tracking": "تتبع الأصول",
  Immobilizer: "جهاز منع الحركة",
  Cam: "كاميرا"
};

export const submenu=  {
    Drivers: "السائقين",
    Vehicles: "المركبات",
    "No Signal": "لا إشارة",
    Parking: "موقف سيارات",
    Idling: "تسكع",
    Moving: "متحرك",
    "All Vehicles": "جميع المركبات",
    Alerts: "التنبيهات",
    Routes: "الطرق",
    "Last Week": "الأسبوع الماضي",
    Yesterday: "أمس",
    Today: "اليوم",
    Reminder: "تذكير",
  "Fleet Expenditure": "نفقات الأسطول",
  "Service Reminder": "تذكير بالخدمة",
  "Documents Reminder": "تذكير المستندات",
  "Other Expense": "مصروفات أخرى",
  Fuel: "الوقود",
  Services: "الخدمات",
  "Service Centers": "مراكز الخدمة",
  "Field Staff": "موظفو الميدان",
  "Service Center": "مركز الخدمة",
  Payments: "المدفوعات",
  "Verified Bills": "الفواتير التي تم التحقق منها",
  "New Bills": "الفواتير الجديدة",
  DARB: "درب",
  SALIK: "سالِك",
  Points: "نقاط",
  Zones: "مناطق",
   "Map View": "عرض الخريطة",
  "Active Tasks": "المهام النشطة",
  "Not Available": "غير متاح",
  "On Task": "في مهمة",
  "Available": "متاح",
  "All": "الكل",
}



// Community
export const GET_COMMUNITY_SETTINGS = "GET_COMMUNITY_SETTINGS";

// Profile actions

export const editPassword = (data) => {
  const user = getStorage("__user__", "object");

  return async (dispatch) => {
    const response = await PostRequest(
      `/user/editLastUserPassword/${user.id}`,
      data
    );
    return response;
  };
};

export const updateprofile = (data) => {
  return async (dispatch) => {
    const response = await PostRequest(`/user/updateprofile`, data);
    return response;
  };
};

// Other Settings

export const getOrganizationSettings = (data = {}) => {
  return async (dispatch) => {
    const response = await TaskManagerRequest(
      `/settings/organization/get`,
      data
    );

    dispatch({
      type: GET_ORGANIZATION_SETTING,
      value: response,
    });
  };
};

export const getGraphicalReports = (data = {}) => {
  return async (dispatch) => {
    const response = await TaskManagerRequest(`/list/graphical-reports`, data);

    dispatch({
      type: GET_GRAPHICAL_REPORTS,
      value: response,
    });
  };
};

export const getOrganizationVehicles = (data = {}) => {
  return async (dispatch) => {
    const response = await TaskManagerRequest(`/admin/vehicles`, data);
    dispatch({
      type: GET_ORGANIZATION_VEHICLES,
      value: response,
    });
  };
};

export const getOrganizationVehiclesAsPerKey = (
  data = { wantBasedOnKeyValue: false }
) => {
  return async (dispatch) => {
    const response = await TaskManagerRequest(`/admin/vehicles`, data);

    dispatch({
      type: GET_ORGANIZATION_VEHICLES_BASED_ON_KEY,
      value: response,
    });
  };
};

// Account Setting

export const setGroups = () => {
  const groups = getStorage("__groups__", "object");

  return (dispatch) => {
    dispatch({
      type: SET_GROUPS,
      value: groups,
    });
  };
};

export const toggleVehicleChecked = (data) => {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_VEHICLE_CHECKED,
      value: data,
    });
  };
};

export const toggleVehicleGroupChecked = (data) => {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_VEHICLE_GROUP_CHECKED,
      value: data,
    });
  };
};

export const getReportGroupNames = () => {
  return async (dispatch) => {
    const response = await GetRequest("/reports/getReportGroupNames", {});

    dispatch({
      type: GET_REPORT_GROUP_NAMES,
      value: response,
    });
  };
};

export const getReportNames = (type="", all=false) => {
  return async (dispatch) => {
    const url = !all ? "/reports/getReportNames?type=" + type : "/reports/getReportNames/all"
    const response = await GetRequest(url, {});

    dispatch({
      type: GET_REPORT_NAMES,
      value: response,
    });
  };
};

export const toggleSelectReport = (data) => {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_SELECT_REPORT,
      value: data,
    });
  };
};

export const getSubusers = () => {
  return async (dispatch) => {
    const id = getStorage("__user__", "object")
      ? getStorage("__user__", "object").id
      : null;

    const response = await GetRequest(
      "/reports/getSubusersOfPartner/" + id,
      {}
    );

    dispatch({
      type: GET_SUBUSERS,
      value: response,
    });
  };
};

export const addSubusers = (data) => {
  return async (dispatch) => {
    try {
      const response = await PostRequest("/user/addNewSubUser", data);
      return response;
    } catch (error) {
      return error;
    }
  };
};

export const addAccountSettings = (id, data) => {
  return async (dispatch) => {
    try {
      const response = await PostRequest(
        `/user/addAccountSettings/${id}`,
        data
      );
      return response;
    } catch (error) {
      return error;
    }
  };
};

export const editSubuser = (user_id, data = {}) => {
  return async (dispatch) => {
    const id = getStorage("__user__", "object")
      ? getStorage("__user__", "object").id
      : null;
    const org_id = getStorage("org_id");

    const response = await PostRequest(
      `/user/editSubUser/${user_id}/${org_id}`,
      data
    );
  };
};

export const editSubuserPassword = (user_id, data = {}) => {
  return async (dispatch) => {
    const response = await PostRequest(
      "/user/editSubUserPassword/" + user_id,
      data
    );
    return response;
  };
};

export const deleteSubuser = (user_id) => {
  return async (dispatch) => {
    const response = await GetRequest("/user/deletesubuser/" + user_id, {});
  };
};

export const getSingleSubusersDetail = (id) => {
  return async (dispatch) => {
    const response = await GetRequest(
      "/user/getSingleSubUserDetials/" + id,
      {}
    );

    dispatch({
      type: GET_SUBUSER,
      value: response,
    });
  };
};

// Live settings

export const getShareLocationSettings = (id) => {
  return async (dispatch) => {
    const user = getStorage("__user__", "object");

    const response = await GetRequest(
      `/user/getShareLocationSettings/${user.id}`,
      {}
    );

    dispatch({
      type: GET_SHARE_LOCATION_SETTINGS,
      value: response,
    });
  };
};

export const saveShareLocationSettings = (data) => {
  return async (dispatch) => {
    const user = getStorage("__user__", "object");

    const response = await PostRequest(
      `/user/saveShareLocationSettings/${user.id}`,
      data
    );

    dispatch({
      type: GET_SHARE_LOCATION_SETTINGS,
      value: data,
    });
  };
};

export const deleteObjGroup = (id) => {
  return async (dispatch) => {
    const response = await GetRequest(`/user/deleteObjGroup/${id}`, {});

    if (response.status == "success") {
      var groups = getStorage("groups_info", "object");

      var exist = groups.findIndex((g) => g.id == id);
      if (exist != -1) {
        groups.splice(exist, 1);
        setStorage("groups_info", groups, "object");
      }
    }

    return true;
  };
};

export const createObjGroup = (data) => {
  return async (dispatch) => {
    const org_id = getStorage("org_id");

    const response = await PostRequest(`/user/objGroupCreate/${org_id}`, data);

    var groups = getStorage("groups_info", "object");
    groups = [response, ...groups];
    setStorage("groups_info", groups, "object");

    return response;
  };
};

export const getSingleObjectGroup = (id) => {
  return async (dispatch) => {
    const response = await GetRequest(`/user/getSingleObjectGroup/${id}`, {});

    dispatch({
      type: GET_SINGLE_OBJ_GROUP,
      value: response[0],
    });
  };
};

export const editObjGroup = (data) => {
  return async (dispatch) => {
    const response = await PostRequest(
      `/user/editObjGroup/${data.obj_group_id}`,
      data
    );

    if (response) {
      var groups = getStorage("groups_info", "object");

      var exist = groups.findIndex((g) => g.id == data.obj_group_id);
      if (exist != -1) {
        groups[exist]["name"] = data["obj_group_name"];
        setStorage("groups_info", groups, "object");
      }
    }

    return true;
  };
};

// Report scheduler

export const getAllReportSchedulers = () => {
  return async (dispatch) => {
    const response = await GetRequest("/reports/getAllReportSchedulers", {});

    dispatch({
      type: GET_REPORT_SCHEDULERS,
      value: response.reportSchedulers,
    });
  };
};

export const getReportSchedule = () => {
  return async (dispatch) => {
    const response = await GetRequest("/reports/getReportNames/schedule", {});

    dispatch({
      type: GET_REPORT_SCHEDULE,
      value: response,
    });
  };
};

export const getSingleReportScheduler = (id) => {
  return async (dispatch) => {
    const response = await GetRequest(
      `/reports/getSingleReportScheduler/${id}`,
      {}
    );
    var data = {};
    if (response.SinglereportScheduler) {
      if (
        response.SinglereportScheduler.data &&
        response.SinglereportScheduler.data.length > 0
      )
        data = { ...data, ...response.SinglereportScheduler.data[0] };
      if (response.SinglereportScheduler.objectIDs)
        data = { ...data, objects: response.SinglereportScheduler.objectIDs };
    }
    dispatch({
      type: GET_SINGLE_REPORT_SCHEDULER,
      value: data,
    });
  };
};

export const deleteReportScheduler = (id) => {
  return async (dispatch) => {
    const response = await GetRequest(
      `/reports/deleteReportScheduler/${id}`,
      {}
    );
    return response;
  };
};

export const addReportScheduler = (data) => {
  return async (dispatch) => {
    const org_id = getStorage("org_id");

    const response = await PostRequest(
      `/reports/addReportScheduler/${org_id}`,
      data
    );
    return response;
  };
};

export const editReportScheduler = (data) => {
  return async (dispatch) => {
    const response = await PostRequest(
      `/reports/editReportScheduler/${data.report_scheduler_id}`,
      data
    );
    return response;
  };
};

// Notifications

export const getNotifications = () => {
  return async (dispatch) => {
    const response = await GetRequest("/event/getSettingsNotifications", {});

    dispatch({
      type: GET_NOTIFICATIONS,
      value: response,
    });
  };
};

export const deleteNotification = (id) => {
  return async (dispatch) => {
    const response = await GetRequest(
      `/event/deleteSettingsNotification/${id}`,
      {}
    );
    return response;
  };
};

export const addNotification = (data) => {
  return async (dispatch) => {
    const org_id = getStorage("org_id");

    const response = await PostRequest(
      `/event/addNewSettingsNotification/${org_id}`,
      data
    );
    return response;
  };
};

export const getallGeozones = () => {
  return async (dispatch) => {
    const response = await GetRequest("/geozones/getallGeozones", {});

    dispatch({
      type: GET_GEOZONES,
      value: response.geozone,
    });
  };
};

export const onSelectGeozones = (data) => {
  return async (dispatch) => {
    dispatch({
      type: ON_SELECT_GEOZONE,
      value: data,
    });
  };
};

export const onSelectAllGeozones = (data) => {
  return async (dispatch) => {
    dispatch({
      type: ON_SELECT_ALL_GEOZONE,
      value: data,
    });
  };
};

export const getSingleSettingsNotification = (id) => {
  return async (dispatch) => {
    const response = await GetRequest(
      `/user/getSingleSettingsNotification/${id}`,
      {}
    );
    var data = {};

    if (response) {
      if (response.data && response.data.length > 0)
        data = { ...data, ...response.data[0] };
      if (response.objects) data = { ...data, objects: response.objects };
      if (response.geozones) data = { ...data, geozones: response.geozones };
    }

    dispatch({
      type: GET_SINGLE_NOTIFICATION,
      value: data,
    });
  };
};

export const editNotification = (data) => {
  return async (dispatch) => {
    const response = await PostRequest(
      `/user/editSettingsNotification/${data.notification_id}`,
      data
    );
    return response;
  };
};

// Dashboard setting

export const getdashboardsettings = () => {
  return async (dispatch) => {
    const response = await GetRequest("/user/getdashboardsettings", {});

    dispatch({
      type: GET_DASHBOARD_SETTINGS,
      value: response,
    });
  };
};

export const updatedashboardsettings = (data) => {
  return async (dispatch) => {
    const response = await PostRequest(`/user/updatedashboardsettings`, data);
    return response;
  };
};

// Task Manager Settings

export const getTaskCheckSettings = () => {
  return async (dispatch) => {
    const user = getStorage("__user__", "object");

    const response = await GetRequest(
      `/tasks/getTaskCheckSettings/${user.id}`,
      {}
    );

    dispatch({
      type: GET_TASK_CHECK_SETTINGS,
      value: response,
    });
  };
};

export const editTaskSettingsCheck = (data) => {
  return async (dispatch) => {
    const response = await PostRequest(
      `/tasks/EdittaskSettingsCheck/${data.task_check_id}`,
      data
    );
    return response;
  };
};

export const getTaskMapSettings = () => {
  return async (dispatch) => {
    const user = getStorage("__user__", "object");

    const response = await GetRequest(
      `/tasks/getTaskMapSettings/${user.id}`,
      {}
    );

    dispatch({
      type: GET_TASK_MAP_SETTINGS,
      value: response.data,
    });
  };
};

export const saveTaskMapSettings = (data) => {
  return async (dispatch) => {
    const user = getStorage("__user__", "object");

    const response = await PostRequest(
      `/tasks/saveTaskMapSettings/${user.id}`,
      data
    );
    return response;
  };
};

export const getTaskReviewSettings = () => {
  return async (dispatch) => {
    const user = getStorage("__user__", "object");

    const response = await GetRequest(
      `/tasks/getTaskReviewSettings/${user.id}`,
      {}
    );

    dispatch({
      type: GET_TASK_REVIEW_SETTINGS,
      value: response.data,
    });
  };
};

export const saveTaskReviewSettings = (data) => {
  return async (dispatch) => {
    const user = getStorage("__user__", "object");

    const response = await PostRequest(
      `/tasks/saveTaskReviewSettings/${user.id}`,
      data
    );
    return response;
  };
};

export const getReportConfirmationDetails = () => {
  return async (dispatch) => {
    const user = getStorage("__user__", "object");

    const response = await GetRequest(
      `/tasks/getTaskStatusReportConfirmationDetails/${user.id}`,
      {}
    );

    dispatch({
      type: GET_REPORT_CONFORMATION_DETAILS,
      value: response.data,
    });
  };
};

export const saveReportConfirmationDetails = (data) => {
  return async (dispatch) => {
    const user = getStorage("__user__", "object");

    const response = await PostRequest(
      `/tasks/saveTaskStatusReportConfirmationDetails/${user.id}`,
      data
    );
    return response;
  };
};

export const getTaskAttributesForEdit = () => {
  return async (dispatch) => {
    const user = getStorage("__user__", "object");

    const response = await TaskManagerRequest(`/task-attributes`, {});

    dispatch({
      type: GET_TASK_ATTRIBUTES,
      value: response,
    });
  };
};

export const changeTaskAttributes = (data) => {
  return async (dispatch) => {
    dispatch({
      type: GET_TASK_ATTRIBUTES,
      value: data,
    });
  };
};

export const deleteTaskAttribute = (data) => {
  return async (dispatch) => {
    // const response = await GetRequest(`/tasks/deleteTaskAttribute/${id}`, {})
    const response = await TaskManagerRequest(`/delete/task-attributes`, data);
    return response;
  };
};

export const AddTaskAttributes = (data) => {
  return async (dispatch) => {
    const user = getStorage("__user__", "object");

    // const response = await PostRequest(`/tasks/AddTaskAttributes/${user.id}`, data)
    const response = await TaskManagerRequest(`/add/task-attributes`, data);
    return response;
  };
};

export const getTaskSettings = () => {
  return async (dispatch) => {
    // const response = await GetRequest(`/user/gettasksettings`, {})
    const response = await TaskManagerRequest(`/task/settings`, {});

    dispatch({
      type: GET_TASK_SETTINGS,
      value: response,
    });
  };
};

export const getSystemSettings = () => {
  return async (dispatch) => {
    const response = await TaskManagerRequest(`/system/settings`, {});

    dispatch({
      type: GET_SYSTEM_SETTINGS,
      value: response,
    });
  };
};

export const changeTaskSettings = (data) => {
  return async (dispatch) => {
    dispatch({
      type: GET_TASK_SETTINGS,
      value: data,
    });
  };
};

export const updateTaskSettings = (data) => {
  return async (dispatch) => {
    const response = await PostRequest(`/user/updatetasksettings`, data);
    return response;
  };
};

export const getTaskCustomerGroups = () => {
  return async (dispatch) => {
    const user = getStorage("__user__", "object");

    const response = await GetRequest(
      `/tasks/getTaskCustomerGroups/${user.id}`,
      {}
    );

    dispatch({
      type: GET_TASK_CUSTOMER_GROUPS,
      value: response,
    });
  };
};

export const getTaskDriverGroups = () => {
  return async (dispatch) => {
    // const response = await GetRequest(`/tasks/getTaskDriverGroups/${user.id}`, {})
    const response = await TaskManagerRequest(`/get/driver/groups`);

    dispatch({
      type: GET_TASK_DRIVER_GROUPS,
      value: response,
    });
  };
};

export const AddTaskDriverGroup = (data) => {
  return async (dispatch) => {
    const response = await PostRequest(`/tasks/AddTaskDriverGroup`, data);
    return response;
  };
};

export const getSingleTaskDriverGroup = (id) => {
  return async (dispatch) => {
    // const response = await GetRequest(`/tasks/getSingleTaskDriverGroup/${id}`, {})
    const response = await TaskManagerRequest(`/get/driver/group`, {
      group: id,
    });

    dispatch({
      type: GET_SINGLE_TASK_DRIVER_GROUPS,
      value: response,
    });
  };
};

export const EditTaskDriverGroup = (data) => {
  return async (dispatch) => {
    // const response = await PostRequest(`/tasks/EditTaskDriverGroup/${data.driver_group_id}`, data)
    const response = await TaskManagerRequest(`/update/group`, data);
    return response;
  };
};

export const deleteTaskDriverGroup = (id) => {
  return async (dispatch) => {
    const response = await GetRequest(`/tasks/deleteTaskDriverGroup/${id}`, {});
    return response;
  };
};

export const getTaskSubUsers = () => {
  return async (dispatch) => {
    const user = getStorage("__user__", "object");

    const response = await GetRequest(`/tasks/getSubUser/${user.id}`, {});

    dispatch({
      type: GET_TASK_SUBUSER,
      value: response,
    });
  };
};

export const getTaskDrivers = () => {
  return async (dispatch) => {
    const user = getStorage("__user__", "object");

    // const response = await GetRequest(`/tasks/getTaskDrivers/${user.id}/today`, {})
    const response = await TaskManagerRequest(`/get/drivers`, {});

    dispatch({
      type: GET_TASK_DRIVERS,
      value: response,
    });
  };
};

export const getTaskDriversofSubuser = (id) => {
  return async (dispatch) => {
    const response = await GetRequest(
      `/tasks/getTaskDriversofSubuser/${id}`,
      {}
    );

    dispatch({
      type: GET_TASK_DRIVERS_OF_SUBUSER,
      value: response,
    });
  };
};

export const changeTaskDriversofSubuser = (data) => {
  return async (dispatch) => {
    dispatch({
      type: GET_TASK_DRIVERS_OF_SUBUSER,
      value: data,
    });
  };
};

export const AssignTaskDriversToSubuser = (data) => {
  return async (dispatch) => {
    const response = await PostRequest(
      `/tasks/AssignTaskDriversToSubuser`,
      data
    );
    return response;
  };
};

export const AddTaskDriver = (data) => {
  return async (dispatch) => {
    const user = getStorage("__user__", "object");

    const response = await PostRequest(`/tasks/AddTaskDriver/${user.id}`, data);
    return response;
  };
};

export const uploadTaskDriverImage = (data, id) => {
  return async (dispatch) => {
    const response = await PostRequest(
      `/tasks/uploadTaskDriverImage/${id}`,
      data
    );
    return response;
  };
};

export const getOrganizationStorage = () => {
  return async (dispatch) => {
    const response = await TaskManagerRequest(`/storage`, {});

    dispatch({
      type: GET_ORGANIZATION_STORAGE,
      value: response,
    });
  };
};

export const getInspectionSettings = () => {
  return async (dispatch) => {
    const response = await TaskManagerRequest(`/settings/inspection`, {});
    dispatch({
      type: GET_INSPECTION_SETTINGS,
      value: response,
    });
  };
};

export const updateInspectionSettings = (key, data, btnID) => {
  return async (dispatch) => {
    await TaskManagerRequest(
      "/settings/inspection/update",
      {
        [key]: data,
      },
      false,
      btnID
    );
    dispatch({
      type: UPDATE_INSPECTION_SETTINGS,
      value: data,
      key: key,
    });
  };
};

// Community
export const getCommunitySettings = () => {
  return async (dispatch) => {
    const response = await TaskManagerRequest(`/settings/community`, {});
    dispatch({
      type: GET_COMMUNITY_SETTINGS,
      value: response,
    });
  };
};

export const updateCommunitySettings = (data, key, btnId) => {
  return async (dispatch) => {
    const response = await TaskManagerRequest(
      "/settings/community/update",
      data,
      false,
      btnId
    );
    if (key) {
      dispatch({
        type: GET_COMMUNITY_SETTINGS,
        value: response[key],
        key: key,
      });
    } else {
      dispatch({
        type: GET_COMMUNITY_SETTINGS,
        value: response,
      });
    }
  };
};

export const changeCommunitySettings = (data, key) => {
  return async (dispatch) => {
    dispatch({
      type: GET_COMMUNITY_SETTINGS,
      value: data,
      key: key,
    });
  };
};

export const deleteCommunityAttribute = (data) => {
  return async (dispatch) => {
    // const response = await GetRequest(`/tasks/deleteTaskAttribute/${id}`, {})
    const response = await TaskManagerRequest(`/settings/community/delete`, data);
    return response;
  };
};

// switch value to eng or ar
export const switchLanguage = (value = "en") => (
  {
    type : SWITCH_LANGUAGE,
    value : value
  }
);