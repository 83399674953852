import React, { useState, useEffect } from 'react'

const Input = (props) => {

   const [value, setValue] = useState(props.value)

   useEffect(() => {
      setValue(props.value)
   }, [props.value])


   const onChangeValue = (event) => {

      const re = /^[0-9\b]+$/;

      if(props.options && props.options.onlyNumber){
         if (event.target.value === '' || re.test(event.target.value)) {
            setValue(+event.target.value)
            if (props.onChange) props.onChange(+event.target.value)
          }
      } else {
         setValue(event.target.value)
         if (props.onChange) props.onChange(event.target.value) 
      }

   }

   return (
      <fieldset className={props.className}>
         <legend><span>{props.label}</span></legend>
         <input
            required={props.required ? props.required : false}
            type={props.type ? props.type : 'text'}
            disabled={props.disabled ? props.disabled : false}
            readOnly={props.readOnly ? props.readOnly : false}
            value={value}
            placeholder={props.placeholder ? props.placeholder : ''}
            style={{ fontSize: '12px', ...props.style }}
            name={props.name}
            defaultValue={props.defaultValue ? props.defaultValue : ''}
            onChange={onChangeValue}
            onClick={props.onClick ? props.onClick : null}
            autoComplete={props.autoComplete ? props.autoComplete : 'off'}
            className="focus-style-none"
            {...props.options}
            step={props?.step ? props?.step : null}
         />
      </fieldset>
   )
}

export default Input;