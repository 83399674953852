import moment from "moment";
import React from "react";
import { AiFillStar } from "react-icons/ai";
// import { useSelector } from 'react-redux';
import ReactTooltip from "react-tooltip";
import { isArray } from "underscore";
import { _customerFields } from "../FieldsCard/CustomerField";
import { _driverFields } from "../FieldsCard/DriverFields";
import ChatCount from "./ChatCount/ChatCount";
import StatusButton from "./StatusButton";

import "./taskCard.css";
import { toHHMMSS } from "../../reports/ReportFilter";
import { TASK_ASSETS_URL } from "../../../environments/environments";
import { _getTimeDuration } from "../../../configs/duration";
import { FaUsers } from "react-icons/fa";

export const _getRowFields = (cardSettings, rowNumber) => {
  if (!cardSettings) return [];

  return cardSettings.filter((cs) =>
    rowNumber == 2 ? cs.row == 2 : !cs.row || cs.row == "1"
  );
};

export const matchWithField = (field) => {
  if (field.type == "job" && field.label == "Job Name")
    field.type = "description";
  if (field.type == "s_time") field.type = "fromTime";
  if (field.type == "e_time") field.type = "toTime";
  if (field.type == "s_address") field.type = "startAddress";
  if (field.type == "e_address") field.type = "endAddress";
  if (field.type == "map") field.type = "endAddress";
  if (field.type == "contact") field.type = "phoneNumber";
  if (field.type == "endAddress") field.type = "address";

  return field;
};

export const _getFieldData = (
  task,
  field,
  statusList = [],
  statusLocationsList = [],
  showFields = {}
) => {
  var statusChangedByList =
    showFields && isArray(showFields?.taskStatus)
      ? showFields.taskStatus.map((s) => `${s.label.toLowerCase()} by`)
      : [];

  field = matchWithField(field);

  if (statusList.includes(field.key)) {
    if (task.statusActivities && isArray(task.statusActivities)) {
      var reversedArray = [...task.statusActivities].reverse();

      const exist = reversedArray.findIndex(
        (t) => `${t.status.toLowerCase()} time` == field.label.toLowerCase()
      );
      return exist != -1
        ? moment(reversedArray[exist].date).format("yyyy-MM-DD HH:mm")
        : "-";
    } else {
      return "-";
    }
  }

  if (statusLocationsList.includes(field.key)) {
    if (task.statusActivities && isArray(task.statusActivities)) {
      var reversedArray = [...task.statusActivities].reverse();

      const exist = reversedArray.findIndex(
        (t) => `${t.status.toLowerCase()} location` == field.label.toLowerCase()
      );
      return exist >= 0 && reversedArray[exist].location
        ? reversedArray[exist].location?.address
        : "-";
    } else {
      return "-";
    }
  }
  if (statusChangedByList.includes(field.label.toLowerCase())) {
    if (task.statusActivities && isArray(task.statusActivities)) {
      var reversedArray = [...task.statusActivities].reverse();

      const exist = reversedArray.findIndex(
        (t) => `${t.status.toLowerCase()} by` == field.label.toLowerCase()
      );
      return exist >= 0 && reversedArray[exist].user
        ? reversedArray[exist].user
        : "-";
    } else {
      return "-";
    }
  }

  // FROM TASK
  if (task[field.key] && field.type !== "populate")
    return (
      <small className="task__stats__val font-13">{task[field.key]}</small>
    );

  //  FOR TIME
  if (field.type == "time")
    return (
      <small className="task__stats__val font-13">
        {task?.fromTime}-{task?.toTime}
      </small>
    );

  // // FROM DRIVER
  // if(task.driver && task.driver[_driverFields[field.type]]) return <small className="task__stats__val">{ task.driver[_driverFields[field.type]] }</small>;

  // const _cf = _customerFields[field.type] ? _customerFields[field.type] : field.type

  // FROM CUSTOMER
  if (field.key == "customer" && field.type == "populate" && task.customer)
    return (
      <small className="task__stats__val font-13">
        {task.customer.name} ({task.customer.phoneNumber})
      </small>
    );

  // FROM ATTRIBUTES
  if (task.attributes && task.attributes[field.key]) {
    if (["Image", "image"].indexOf(field.type) !== -1) {
      return (
        <img
          src={`${TASK_ASSETS_URL}/${task.attributes[field.key]}`}
          width="60"
          height="60"
          style={{ objectFit: "cover" }}
        />
      );
    } else if (["Sigature", "signature"].indexOf(field.type) !== -1) {
      return (
        <img
          src={task.attributes[field.key]}
          width="60"
          height="60"
          style={{ objectFit: "cover" }}
        />
      );
    } else {
      return (
        <small className="task__stats__val font-13">
          {task.attributes[field.key]}
        </small>
      );
    }
  }

  return "-";
};

const AssignedTaskCard = (props) => {
  const {
    id,
    task,
    isActive,
    onClick,
    toggleChatWindow,
    showFields,
    activeDriver = false,
    cardSettings,
    statusList,
    statusLocationsList,
    className = "",
    timelineDuration = false,
    fromTimeLine = false,
  } = props;

  // const activeDriver = useSelector((state) => state.taskReducer.activeDriver);

  const classes = isActive == true ? "singleTaskActive" : "";
  const active = isActive == true ? "taskordersectionActive" : "";

  const firstRow = _getRowFields(cardSettings, 1);
  const seoundRow = _getRowFields(cardSettings, 2);

  return (
    <React.Fragment>
      <div
        onClick={onClick}
        className={`task ${isActive ? "active" : ""} ${className}`}
      >
        <div className="d-flex bd-highlight">
          <div className="mr-auto p-2 bd-highlight">
            <span className="task__tag task__tag--copyright mr-2">
              #{id + 1}
            </span>
            {(task.hasRepeatTask ||
              (task.parentTask && task.parentTask != "")) && (
              <img
                className="mr-2"
                data-tip="Created from the Scheduler"
                src={require("../../../assets/img/repeat.png").default}
                height={20}
                alt=""
              />
            )}

            {task.priority && task.priority == "critical" && (
              <AiFillStar
                data-tip="Critical Priority"
                size={20}
                color={"#2d66ef"}
              />
            )}

            {task.isSharedWithGroup && (
              <FaUsers
                data-tip={
                  task.isSharedJobStartedByDriver
                    ? `This is the group task & work started by ${task.driver.name}`
                    : "Group Task"
                }
                size={20}
                color={task.isSharedJobStartedByDriver ? "#2d66ef" : "grey"}
              />
            )}

            {!activeDriver && (
              <span className="task__stats ml-2">{task.driver?.name}</span>
            )}
          </div>
          {task.uniqueId && ( // .jobId
            <div className="p-2 bd-highlight">
              <span className="task__stats mr-2">
                <span className="font-weight-bold">#</span>
                {task.uniqueId}
              </span>
            </div>
          )}
          <div className="p-2 bd-highlight">
            <span className="task__stats mr-2">
              {task.taskDate && task.taskDate !== "" && (
                <time dateTime={task.taskDate}>
                  <i className="fas fa-calendar-day"></i>{" "}
                  {moment(task.taskDate).format("DD-MM-YYYY")}{" "}
                </time>
              )}
            </span>
          </div>

          {timelineDuration !== false && (
            <div className="p-2 bd-highlight">
              <span className="task__stats">
                {" "}
                Time{" "}
                {moment(task?.EstimatedFromTime, "HH:mm:ss").format(
                  "HH:mm A"
                )}{" "}
                - {moment(task?.EstimatedToTime, "HH:mm:ss").format("HH:mm A")}{" "}
                [
                {_getTimeDuration(
                  task?.EstimatedFromTime,
                  task?.EstimatedToTime
                )}
                ]{" "}
              </span>
              {/* {task.travelingTime ? (
                <span className="task__stats">
                  {" "}
                  Travel Time {toHHMMSS(
                    task.travelingTime * 1000,
                    "HH:mm"
                  )}{" "}
                </span>
              ) : null} */}
            </div>
          )}
          <div className="p-2 bd-highlight">
            <span className="task__owner">
              <StatusButton
                status={task.taskStatus}
                statusList={showFields.taskStatus}
              />
            </span>
          </div>
          {task.isOptimizedByExcludingTimeWindow && fromTimeLine ? (
            <div className="py-2">
              <img
                src={
                  require("../../../assets/img/task_manager_icons/exclude-time-window.png")
                    .default
                }
                style={styles.excludeTimeWindowIcon}
              />
            </div>
          ) : (
            <></>
          )}
        </div>

        {/*  Row 1  */}

        <div
          className="d-flex justify-content-between"
          style={{ borderBottom: "1px solid #f9f9f9" }}
        >
          {firstRow.map((fr, i) => (
            <span key={i} className="align-items-center eclips">
              {" "}
              <small className="task__stats"> {fr.label} : </small>{" "}
              <small className="task__stats__val font-13">
                {_getFieldData(
                  task,
                  fr,
                  statusList,
                  statusLocationsList,
                  showFields
                )}
              </small>{" "}
            </span>
          ))}
        </div>

        {/*  Row 2  */}

        <div className="d-flex justify-content-between">
          {seoundRow.map((fr, i) => (
            <div
              key={i}
              style={{
                maxWidth:
                  seoundRow.length <= 2
                    ? seoundRow.length == 1
                      ? "100%"
                      : "60%"
                    : "33%",
              }}
              className="eclips align-items-center"
            >
              {" "}
              <small className="task__stats"> {fr.label} : </small>{" "}
              <small className="task__stats__val font-13">
                {_getFieldData(task, fr, statusList, statusLocationsList, showFields)}
              </small>{" "}
            </div>
          ))}
        </div>

        {/* <div className='task__tags'>
                    <span className='task__tag task__tag--copyright'>#{id + 1}</span>
                    <span>
                        <span className='task__stats mr-2'><span className="font-weight-bold">#</span>{task.jobId}</span>
                    </span>
                </div>

                <p className="eclips">{task.description}</p>

                <div className='task__stats'>
                    <span><i className="fas fa-user"></i> {task.customer && task.customer.name ? task.customer.name : '-'}</span>
                    <span><i className="fas fa-map-marker"></i> {task.endAddress !== '' ? task.endAddress : '-'}</span>
                    <span><i className="fas fa-comment"></i> 10</span>
                </div> */}
      </div>

      {/* <div className="d-flex" onClick={onClick} >

     <span style={styles.count} className={active}>{id + 1}</span>

     <div style={styles.container} className={`row m-0 mb-2 pointer ${classes}`}>
         <div className="col-md-1 col-sm-1 col-1 pl-1 pr-1" >
             <img src={require('../../../assets/img/task_manager_icons/task-card.svg').default} style={styles.image} />
             <div onClick={(event) => event.stopPropagation()}>
                 <ChatCount countStyle={{paddingTop: 3}} onClick={toggleChatWindow}/>
             </div>
         </div>
         <div className="col-md-3 col-sm-3 col-3 pl-1 pr-1 pt-2" >
             Task ID : #{task.number}
         </div>
         <div className="col-md-2 col-sm-2 col-2 p-0 pt-2" >
             <StatusButton status={task.taskStatus} />
             <button style={styles.status}>Assigned</button> 
         </div>
         <div className="col-md-2 col-sm-2 col-2 pr-1" >
             <p style={styles.grayHead}>Job</p>
             <p className="mb-0 font-12 text-black">{task.description}</p>
         </div>
         <div className="col-md-2 col-sm-2 col-2 pl-1 pr-1" >
             <p style={styles.grayHead}>Date</p>
             <p className="mb-0 font-12 text-black">{moment(task.taskDate).format('DD-MM-YYYY')}</p>
         </div>
         <div className="col-md-2 col-sm-2 col-2 pl-1 pr-1" >
             <p style={styles.grayHead}>Customer</p>
             <p className="mb-0 font-12 text-black">{task.customer ? task.customer.name : ''}</p>
         </div>
     </div>
 </div> */}

      <ReactTooltip class="tooltip" />
    </React.Fragment>
  );
};

const styles = {
  container: {
    backgroundColor: "#ffffff",
    fontSize: "13px",
    padding: "12px 5px",
    boxShadow: "0 4px 15px 0 rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    width: "100%",
  },
  status: {
    outline: "none",
    border: "1px solid #1214b1",
    color: "#1214b1",
    fontSize: "11px",
    borderRadius: "3px",
    whiteSpace: "nowrap",
    backgroundColor: "#cae0f2",
  },
  grayHead: {
    color: "gray",
    fontSize: "12px",
    marginBottom: "-3px",
  },
  count: {
    padding: "0px 10px",
    border: "1px solid #e2e2e2",
    height: "22px",
    borderRight: "0px",
    marginTop: "10px",
    fontSize: "12px",
    borderRadius: "4px 0 0 4px",
  },
  image: {
    position: "relative",
    top: -10,
  },
  excludeTimeWindowIcon: {
    height: 20,
  },
};

export default AssignedTaskCard;
